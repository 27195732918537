import React from "react";
import PropTypes from "prop-types";
//import moment from "moment";
import moment from "moment-timezone";

moment.locale('de');
moment.tz.setDefault("Europe/Berlin");

const TeaserKursUpcomingDates = ({
  item,
  dateFormat,
  timeFormat,
  futureAppointments,
}) => {
  return (
    <table className="upcoming-dates">
      <tbody>
        {futureAppointments.map((date, index) => (
          <tr key={index}>
            <td>
              {moment
                .utc(date.entity.fieldDatumKursTermin.value)
                .local()
                .format(dateFormat)}
            </td>
            <td>
              {moment
                .utc(date.entity.fieldDatumKursTermin.value)
                .local()
                .format(timeFormat)}{" "}
              –{" "}
              {moment
                .utc(date.entity.fieldDatumKursTermin.endValue)
                .local()
                .format(timeFormat)}{" "}
              Uhr
            </td>
            <td className="full-width">{date.entity.fieldOrt}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

TeaserKursUpcomingDates.propTypes = {
  item: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  timeFormat: PropTypes.string.isRequired,
  futureAppointments: PropTypes.array.isRequired,
};

export default TeaserKursUpcomingDates;
