const encodeUrl = (str) => {
  str = (str + '').toString().replace(/\s/g, '-')
    .toLowerCase()
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .replace(/([^a-zA-Z0-9])+/g, "-");
  return encodeURIComponent(str);
}

export { encodeUrl };