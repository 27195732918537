import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ErrorBoundary from "../../../../error-boundary";
import Image from "../../../image/image";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-component";

/**
 * Maximum of three news or person entries.
 *
 * @todo Events.
 */
class ParagraphDossier extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-dossier": true
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldHeading && (
          <div className="container">
            <div className="row">
              <div className="col-10">
                <h2>{this.props.content.fieldHeading}</h2>
              </div>
            </div>
          </div>
        )}

        <div className="container">
          <div className="row">
            <div className="col-10">
              <Masonry className="row">
                {this.props.content.fieldReferencedLinks && this.props.content.fieldReferencedLinks.map((item, index) => {
                    let headline = item.entity.fieldHeading,
                      image = item.entity.fieldImage ? item.entity.fieldImage.entity.fieldMediaImage : null;

                    if (item.entity.fieldReferencedContent && item.entity.fieldReferencedContent.entity.entityLabel && !item.fieldHeading) {
                      headline = item.entity.fieldReferencedContent.entity.entityLabel;
                    }

                    if (item.entity.fieldReferencedContent && item.entity.fieldReferencedContent.entity.fieldTeaserbild && !item.fieldImage && item.entity.fieldReferencedContent.entity.fieldTeaserbild.entity) {
                      image = item.entity.fieldReferencedContent.entity.fieldTeaserbild.entity.fieldMediaImage;
                    }

                    let content = <>
                      {image &&
                        <Image
                          data={image}
                          fullPage={true}
                          nodeTitle={headline}
                        />
                      }
                      <div className="info-wrapper">
                        {headline &&
                          <h3>{headline}</h3>
                        }
                        {item.entity.fieldText &&
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: item.entity.fieldText.processed,
                            }}
                          />
                        }
                      </div>
                    </>;

                    return(
                      <div className="col-10 col-md-custom teaser-wrapper" key={index}>
                        <div className="inner-wrapper">
                          {item.entity.fieldLink !== null ? (
                            <>
                              {item.entity.fieldLink.routed ? (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.entity.fieldLink.url.path}
                                >
                                  {content}
                                </a>
                              ) : (
                                <Link to={item.entity.fieldLink.url.path}>
                                  {content}
                                </Link>
                              )}
                            </>
                          ) : (
                            <ErrorBoundary>
                              {content}
                            </ErrorBoundary>
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </Masonry>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphDossier.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    })
  }),
};

export default ParagraphDossier;
