import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";

import Link from "../../../link/link";
import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphTeaserSlider extends Component {
  state = {
    sliderSettings: {
      arrows: true,
      dots: false,
      nextArrow: <ArrowRight32 width={48} height={48} viewBox="0 0 32 24" />,
      prevArrow: <ArrowLeft32 width={48} height={48} viewBox="0 0 32 24" />,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: false,
    },
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-slider": true,
    });

    return (
      <section className={sectionClassNames}>
        <ErrorBoundary>
          <Slider {...this.state.sliderSettings}>
            {this.props.content.fieldTeaserSlides.map((item, index) => (
              <React.Fragment key={index}>
                <ErrorBoundary>
                  <Image
                    className="background-image"
                    data={item.entity.fieldImage.entity.fieldMediaImage}
                  />
                </ErrorBoundary>
                <div>
                  <article className="container">
                    <div className="row">
                      <div className="col-10 position-static">
                        {item.entity.fieldTeaserueberschrift ? (
                          <h3>{item.entity.fieldTeaserueberschrift}</h3>
                        ) : (
                          <h3>
                            {item.entity.fieldInhaltLink.url.entity.title}
                          </h3>
                        )}
                        <Link
                          className="btn btn-primary"
                          link={item.entity.fieldInhaltLink}
                        />
                        <p className="pager d-none d-md-block">
                          Slide {index + 1} /{" "}
                          {this.props.content.fieldTeaserSlides.length}
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </React.Fragment>
            ))}
          </Slider>
        </ErrorBoundary>
      </section>
    );
  }
}

ParagraphTeaserSlider.propTypes = {
  content: PropTypes.shape({
    fieldTeaserSlides: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTeaserueberschrift: PropTypes.string.isRequired,
          fieldImage: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string.isRequired,
                }),
              }),
            }),
          }),
          fieldInhaltLink: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
              entity: PropTypes.shape({
                title: PropTypes.string
              })
            })
          }),
        }),
      })
    ),
  }),
};

export default ParagraphTeaserSlider;
