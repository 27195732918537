const adminAppAction = (adminApp) => ({
    adminApp,
    type: "ADMIN_APP",
  }),
  pagerFullPagesAction = (config) => ({
    config,
    type: "PAGER_FULL_PAGE",
  }),
  pagerFullPageContextAction = (id) => ({
    id,
    type: "PAGER_FULL_PAGE_CONTEXT_ID",
  }),
  specialDesignAction = (specialDesign) => ({
    specialDesign,
    type: "SPECIAL_DESIGN",
  });

export { adminAppAction, pagerFullPagesAction, pagerFullPageContextAction, specialDesignAction };
