import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Link, {LinkPropType} from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphCallToAction extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-call-to-action": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-10 d-flex justify-content-end justify-content-md-center">
              <ErrorBoundary>
                <Link
                  className="btn btn-primary"
                  link={this.props.content.fieldCallToAction}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphCallToAction.propTypes = {
  content: PropTypes.shape({
    fieldCallToAction: LinkPropType
  })
};

export default ParagraphCallToAction;
