import React from "react";
import PropTypes from "prop-types";

const ModalBody = (props) => {
  return (
    <section className="modal-body">
      {props.children}
    </section>
  )
}

export default ModalBody;