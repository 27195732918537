import React, { useState } from "react";
import Image from "../../image/image";
//import moment from "moment";
import moment from "moment-timezone";

import ModalHeader from "../../modal/modal-header";
import ModalBody from "../../modal/modal-body";
import TeaserKursUpcomingDates from "./teaser-kurs-upcoming-dates";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { encodeUrl } from "../../../lib/encode-url";

moment.locale("de");
moment.tz.setDefault("Europe/Berlin");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const TeaserKursDetailedInformation = ({
  item,
  dateFormat,
  timeFormat,
  hideBooking,
  hideAvailablePlaces,
  futureAppointments,
}) => {
  const [openModalState, setOpenModal] = useState(false);
  const [ticketNumber, setTicketNumber] = useState(0);

  const isEvent =
    (item.fieldKurstyp === "Einzelveranstaltung" ||
      item.fieldKurstyp === "Normalkurs") &&
    (item.fieldVeranstaltungsart === "Veranstaltung" ||
      item.fieldVeranstaltungsart === "Kino");

  const isCafe =
    item.fieldKurstyp === "Einzelveranstaltung" &&
    item.fieldVeranstaltungsart === "Cafe";

  const addTicket = () => {
    setTicketNumber((prev) => prev + 1);
  };
  const removeTicket = () => {
    setTicketNumber((prev) => (prev > 0 ? prev - 1 : 0));
  };

  function openModal() {
    setOpenModal(true);
  }

  function closeModal() {
    setOpenModal(false);
  }

  /**
   * Decides which gebuehren to use.
   *
   * @see https://office.e-fork.net/issues/12094
   *
   * @returns {*|JSX.Element|string}
   */
  const getGebuehr = () => {
    const gebuehren = item.fieldGebuehren;

    const material = gebuehren
      .filter((gebuehr) => gebuehr.entity != null)
      .filter((gebuehr) => {
        return gebuehr.entity.fieldLabel.includes("Materialkosten");
      });

    const keramik = gebuehren
      .filter((gebuehr) => gebuehr.entity != null)
      .filter((gebuehr) => {
        return gebuehr.entity.fieldLabel.includes("Keramik");
      });

    // Jahreskurs.
    const yearly = gebuehren
      .filter((gebuehr) => gebuehr.entity != null)
      .filter((gebuehr) => {
        return gebuehr.entity.fieldLabel === "1. Rate";
      });

    if (yearly.length > 0) {
      return `${yearly[0].entity.fieldBetrag.formatted} / Monat`;
    }

    // Offene Werkstätten.
    const openWorkshops = gebuehren
      .filter((gebuehr) => gebuehr.entity != null)
      .filter((gebuehr) => {
        return gebuehr.entity.fieldLabel.includes("Offene Werkstatt");
      });

    if (openWorkshops.length > 0) {
      let materialAdd = <></>;
      if (material.length > 0 && keramik.length > 0) {
        materialAdd = (
          <>zzgl. Material {material[0].entity.fieldBetrag.formatted} / kg </>
        );
      }

      if (material.length > 0 && keramik.length === 0) {
        materialAdd = (
          <>zzgl. Material {material[0].entity.fieldBetrag.formatted} </>
        );
      }

      return (
        <>
          <b>für die Mindestzeit von 2 Stunden </b>
          {openWorkshops[0].entity.fieldBetrag.formatted} <br />
          {openWorkshops[0].entity.fieldBetragErmaessigt ? (
            <>
              {openWorkshops[0].entity.fieldBetragErmaessigt.formatted}{" "}
              (Ermäßigt) <br />
            </>
          ) : null}
          {materialAdd}
        </>
      );
    }

    // Kompaktkurse / Workshops.
    const compactWorkshops = gebuehren
      .filter((gebuehr) => gebuehr.entity != null)
      .filter((gebuehr) => {
        return (
          gebuehr.entity.fieldLabel.includes("Kompaktkurs") ||
          gebuehr.entity.fieldLabel.includes("Workshop") ||
          gebuehr.entity.fieldLabel.includes("Ferienangebot")
        );
      });

    if (compactWorkshops.length > 0) {
      let materialAdd = <></>;
      if (material.length > 0 && keramik.length > 0) {
        materialAdd = (
          <>zzgl. Material {material[0].entity.fieldBetrag.formatted} / kg </>
        );
      }

      if (material.length > 0 && keramik.length === 0) {
        materialAdd = (
          <>zzgl. Material {material[0].entity.fieldBetrag.formatted} </>
        );
      }

      // Special case, see #15214.
      if (
        !materialAdd.length &&
        !compactWorkshops[0].entity.fieldBetragErmaessigt &&
        parseInt(compactWorkshops[0].entity.fieldBetrag.number) === 0
      ) {
        return "kostenfrei";
      }

      return (
        <>
          {compactWorkshops[0].entity.fieldBetrag.formatted} <br />
          {compactWorkshops[0].entity.fieldBetragErmaessigt ? (
            <>
              {compactWorkshops[0].entity.fieldBetragErmaessigt.formatted}{" "}
              (Ermäßigt) <br />
            </>
          ) : null}
          {materialAdd}
        </>
      );
    }

    // Veranstaltungen.
    const veranstaltungen = gebuehren
      .filter((gebuehr) => gebuehr.entity != null)
      .filter((gebuehr) => {
        return (
          gebuehr.entity.fieldLabel.includes("Veranstaltungen") ||
          gebuehr.entity.fieldLabel.includes("Kino")
        );
      });

    if (
      veranstaltungen.length > 0 &&
      parseInt(veranstaltungen[0].entity.fieldBetrag.number) !== 0
    ) {
      const sollticket = veranstaltungen.filter((gebuehr) => {
        return gebuehr.entity.fieldLabel.includes("Veranstaltungen_Soliticket");
      });

      let sollticketRender = null;
      if (sollticket.length > 0) {
        sollticketRender = (
          <>
            {" "}
            <br /> {sollticket[0].entity.fieldBetrag.formatted} (Sollticket)
          </>
        );
      }

      return (
        <>
          {veranstaltungen[0].entity.fieldBetrag.formatted}
          {veranstaltungen[0].entity.fieldBetragErmaessigt && (
            <>
              {" "}
              <br /> {
                veranstaltungen[0].entity.fieldBetragErmaessigt.formatted
              }{" "}
              (Ermäßigt)
              {sollticketRender}
            </>
          )}
        </>
      );
    }

    if (parseInt(item.fieldGebuehr.number) === 0) {
      return "kostenfrei";
    }

    // Fallback.
    return item.fieldGebuehr.formatted;
  };

  const getLocation = () => {
    const events = item.fieldTermine;

    let locationLabel = item.fieldOrtRef?.entity?.entityLabel
      ? item.fieldOrtRef.entity.entityLabel
      : item.fieldAussenstelle;
    let rooms = null;

    if (events.length > 0) {
      const locations = events.map((event) => {
        return event.entity?.fieldOrt;
      });

      const uniqueLocations = [...new Set(locations)];

      rooms = uniqueLocations
        .join(", ")
        .replace(`${locationLabel}.`, "")
        .replace(locationLabel, "");
    } else {
      rooms = item.fieldOrt;
    }

    if (item.fieldOrtRef?.entity?.path?.alias) {
      return (
        <>
          <a target="_blank" href={item.fieldOrtRef.entity.path.alias}>
            {locationLabel}
          </a>
          {rooms ? `, ${rooms}` : null}
        </>
      );
    }

    // Fallback.
    return `${item.fieldAussenstelle}, ${item.fieldOrt}`;
  };

  const getDozent = () => {
    const events = item.fieldTermine;

    if (item.fieldKursleitungRef.length > 0) {
      return item.fieldKursleitungRef.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 ? ", " : null}
          <a
            target="_blank"
            href={`/team#${encodeUrl(item.entity.entityLabel)}`}
          >
            {item.entity.entityLabel}
          </a>
        </React.Fragment>
      ));
    }

    if (events.length > 0) {
      const dozents = events.map((event) => {
        return event.entity?.fieldDozent;
      });

      const uniqueDozents = [...new Set(dozents)];

      return uniqueDozents.join(", ");
    }

    // Fallback.
    return item.fieldKursleitung;
  };

  let ImageRender = null,
    bookingButtonRender = null;

  if (item.fieldBild !== null) {
    ImageRender = (
      <Image
        data={item.fieldBild.entity.fieldMediaImage}
        nodeTitle={item.title}
      />
    );
  }

  if (!!item.fieldHauptkurs?.entity.fieldBuchenButton?.url?.path) {
    bookingButtonRender = (
      <div className="submit-tickets">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={item.fieldHauptkurs.entity.fieldBuchenButton.url.path}
          className="btn btn-primary"
        >
          {item.fieldHauptkurs.entity.fieldBuchenButton.title}
        </a>
      </div>
    );
  }

  if (!!item.fieldBuchenButton?.url?.path) {
    bookingButtonRender = (
      <div className="submit-tickets">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={item.fieldBuchenButton.url.path}
          className="btn btn-primary"
        >
          {item.fieldBuchenButton.title}
        </a>
      </div>
    );
  }

  if (!!item.fieldHauptkurs?.entity.fieldBild?.entity.fieldMediaImage) {
    ImageRender = (
      <Image
        data={item.fieldHauptkurs.entity.fieldBild.entity.fieldMediaImage}
        nodeTitle={item.title}
      />
    );
  }

  let startValue = moment.utc(item.fieldDatumKurs.value).local(),
    endValue = moment.utc(item.fieldDatumKurs.endValue).local(),
    hasMultipleDates =
      item.fieldTermine.length > 1 && item.fieldTermine[0].entity != null;

  return (
    <>
      <div className="row extendend-content">
        <div
          className="col-10 col-md-5 col-lg-5 text"
          dangerouslySetInnerHTML={{ __html: item.fieldText?.processed }}
        />
        <div className="col-10 col-md-5 col-lg-3 image">{ImageRender}</div>
        <div className="col-10 col-md-5 col-lg-2 informations">
          <div className="place">
            <strong>Ort </strong> {getLocation()}
          </div>
          {!isEvent && !isCafe && (
            <>
              <div className="leading">
                <strong>Kursleitung </strong> {getDozent()}
              </div>
              {hasMultipleDates ? (
                <div className="start">
                  <strong>Start </strong>
                  {startValue.format("DD.MM.YYYY")}
                </div>
              ) : null}
              {startValue.format("DD.MM.YYYY") !==
                endValue.format("DD.MM.YYYY") && (
                <div className="duration">
                  <strong>Dauer </strong> {startValue.format("DD.MM.YYYY")} —{" "}
                  {endValue.format("DD.MM.YYYY")}
                </div>
              )}
            </>
          )}
          <div className="fee">
            {isEvent || isCafe ? (
              <strong>Eintrittspreis </strong>
            ) : (
              <strong>Gebühr </strong>
            )}
            {getGebuehr()}
          </div>
          {!isEvent && !isCafe && (
            <>
              <div className="number">
                <strong>Kursnummer </strong> {item.fieldKursnummer}
              </div>
              {hasMultipleDates && (
                <div className="next-events">
                  <strong onClick={openModal}>Nächste Termine</strong>
                  {/*<img src="/ui-icons/icon_printer.svg" alt="Drucken" />*/}
                </div>
              )}
            </>
          )}

          {item.fieldAnmeldeschluss?.value ? (
            <div>
              <strong>Anmeldeschluss </strong>{" "}
              {moment(item.fieldAnmeldeschluss.value, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              )}
            </div>
          ) : null}

          {!hideBooking ? (
            <>
              {item.fieldFreiePlaetze > 0 ? (
                <div className="tickets">
                  <div className="price">
                    {/*<strong>{item.fieldGebuehr.formatted}</strong>*/}
                    {!hideAvailablePlaces && (
                      <small>noch {item.fieldFreiePlaetze} verfügbar</small>
                    )}
                  </div>
                  {/*<div className="buy-tickets">
                <button className="down" onClick={removeTicket}>
                  <img
                    src="/ui-icons/icon_minus.svg"
                    alt="-1 (Ticket entfernen)"
                  />
                </button>
                <div className="number">{ticketNumber}</div>
                <button className="up" onClick={addTicket}>
                  <img
                    src="/ui-icons/icon_plus.svg"
                    alt="+1 (Ticket hinzufügen)"
                  />
                </button>
              </div>*/}
                  {bookingButtonRender}
                </div>
              ) : (
                <>Ausgebucht</>
              )}
            </>
          ) : null}
        </div>
      </div>
      <Modal
        isOpen={openModalState}
        onRequestClose={closeModal}
        contentLabel="Terminübersicht"
        style={customStyles}
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "unset")}
      >
        <ModalHeader onClose={closeModal} title="Nächste Termine" />
        <ModalBody>
          <TeaserKursUpcomingDates
            item={item}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            futureAppointments={futureAppointments}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

TeaserKursDetailedInformation.propTypes = {
  item: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  timeFormat: PropTypes.string.isRequired,
  futureAppointments: PropTypes.array.isRequired,
};

export default TeaserKursDetailedInformation;
