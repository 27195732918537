import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowDownRight32 } from "@carbon/icons-react";

import Link, { LinkPropType } from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";
import {self} from "../../../../config";

class ParagraphIntromodul extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-intromodul": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-10 col-md-5 animation-col">
              <div className="jks-animation">
                <img src={`${self}/cutouts/jks-cutout-01.svg`} className="cutout" alt={"Ein Scheerenschnitt zur Dekoration."}/>
                <img src={`${self}/cutouts/jks-cutout-02.svg`} className="cutout" alt={"Ein Scheerenschnitt zur Dekoration."}/>
                <img src={`${self}/cutouts/jks-cutout-03.svg`} className="cutout" alt={"Ein Scheerenschnitt zur Dekoration."}/>
                <img src={`${self}/cutouts/jks-cutout-04.svg`} className="cutout" alt={"Ein Scheerenschnitt zur Dekoration."}/>
                <img src={`${self}/cutouts/jks-cutout-05.svg`} className="cutout" alt={"Ein Scheerenschnitt zur Dekoration."}/>
                <img src={`${self}/cutouts/jks-cutout-06.svg`} className="cutout" alt={"Ein Scheerenschnitt zur Dekoration."}/>
                <img src={`${self}/cutouts/jks-cutout-j.svg`} className="cutout letter j" alt={"Das 'J' aus Jugendkunstschule"}/>
                <img src={`${self}/cutouts/jks-cutout-k.svg`} className="cutout letter k" alt={"Das 'K' aus Jugendkunstschule"}/>
                <img src={`${self}/cutouts/jks-cutout-s.svg`} className="cutout letter s" alt={"Das 'S' aus Jugendkunstschule"}/>
                <img src={`${self}/cutouts/jks-cutout-dresden.svg`} className="cutout letter dresden" alt={"Dresden"}/>
              </div>
            </div>
            <div className="col-10 col-md-5 link-col">
              <ul>
                {this.props.content.fieldVerlinkungen.map((item, index) => (
                  <li key={index}>
                    <ErrorBoundary>
                      <Link link={item.entity}/>
                      <span className="inktrap">↗</span>
                    </ErrorBoundary>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphIntromodul.propTypes = {
  content: PropTypes.shape({
    fieldVerlinkungen: PropTypes.arrayOf(LinkPropType),
  }),
};

export default ParagraphIntromodul;
