import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import ReactPaginate from "react-paginate";
import { animateScroll as scroll } from "react-scroll";
import { connect } from "react-redux";
import { compose } from "recompose";
import moment from "moment";
import Masonry from "react-masonry-component";

import teaserNodeQueryEventTagFiltered from "../../../teaser-base/queries/teaser-node-query-event-tag-filtered.graphql";
import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import TeaserPersonOverview from "../../../teaser-base/person/teaser-person-overview";
import TeaserNews from "../../../teaser-base/news/teaser-news";
import ComponentFilterCloud from "./components/component-filter-cloud";
import TeaserAngebot from "../../../teaser-base/angebot/teaser-angebot";
import TeaserKindergeburtstag from "../../../teaser-base/kindergeburtstag/teaser-kindergeburtstag";
import TeaserKurs from "../../../teaser-base/kurs/teaser-kurs";
import { pagerFullPagesAction } from "../../../../app-actions";
import ComponentExtendedTeaserOverviewFilterText from "./components/component-extended-teaser-overview-filter-text";
import ComponentExtendedTeaserOverviewFilterDialogBaum from "./components/component-extended-teaser-overview-filter-dialog-baum";
import ComponentExtendedTeaserOverviewSearch from "./components/component-extended-teaser-overview-search";
import ErrorBoundary from "../../../../error-boundary";
import TeaserEvent from "../../../teaser-base/event/teaser-event";
import TeaserProjekt from "../../../teaser-base/project/teaser-projekt";
import {Accordion, AccordionItem} from "react-accessible-accordion";
import TeaserHighlight from "../../../teaser-base/highlight/teaser-highlight";
import KursOverview from "./kurs/kurs-overview";
import PersonOverviewAccordions from "./persons/person-overview-accordions";
import {encodeUrl} from "../../../../lib/encode-url";
import ScrollUpButton from "react-scroll-up-button";
import ScrollToTop from "../../../scroll-to-top";

class ParagraphExtendedTeaserOverview extends Component {
  static defaultProps = {
    skipQuery: false,
    filterCloudLabels: [null, "Suche nach Tags"],
    filterCloudFilters: ["timeFilters", "fieldZielgruppe", "fieldSchlagwort"],
    filterCloudFilterSingle: false,
    filterCloudMultipleFilterCondition: "AND",
    filterCloudPreventEmptyResult: true,
    usePager: false
  };

  itemSection = React.createRef();


  filterForDay = (nodes, dayFilter) => {
    let filteredNodes = nodes.filter((item) => {
      return item.fieldTagesfilter.some((day) => dayFilter.includes(day));
    });

    return filteredNodes;
  };

  filterForMonth = (nodes, monthFilter) => {
    const unifyDate = (date) => {
      return +moment(date).format("YYYYMM");
    };

    let filteredNodes = nodes.filter((item) => {
      const itemStartDate = unifyDate(item.fieldDatumKurs.value);
      const itemEndDate = unifyDate(item.fieldDatumKurs.endValue);

      const monthMatches = monthFilter.some((selectedMonth) => {
        const unifiedSelectedMonth = unifyDate(selectedMonth);
        return (
          unifiedSelectedMonth >= itemStartDate &&
          unifiedSelectedMonth <= itemEndDate
        );
      });

      return monthMatches;
    });

    return filteredNodes;
  };

  filterItems = (filter = [], search = "") => {
    let filteredItems = [...this.props.nodes.nodeQuery.entities];
    const activeFilter =
        filter.length > 0
          ? filter
          : this.state
          ? this.state.activeFilter
          : null,
      activeSearch =
        search !== "" ? search : this.state ? this.state.activeSearch : null,
      activeMonths =
        this.state?.monthFilter.length > 0 ? this.state.monthFilter : null,
      activeDays =
        this.state?.dayFilter.length > 0 ? this.state.dayFilter : null;

    // Filter for day
    if (activeDays) {
      filteredItems = this.filterForDay(filteredItems, activeDays);
    }
    // Filter for month
    if (activeMonths) {
      filteredItems = this.filterForMonth(filteredItems, activeMonths);
    }
    // Filter for Ferien
    if (this.state?.ferienFilter) {
      filteredItems = filteredItems.filter((item) => {
        return item.fieldFerien;
      });
    }

    if (
      (activeFilter && activeFilter.length > 0) ||
      (activeSearch && activeSearch !== "")
    ) {
      if (
        activeFilter.length === 1 &&
        activeFilter[0].entityId &&
        !activeFilter[0].field
      ) {
        // Default (old) filtering with fieldSchlagwort.
        filteredItems = filteredItems.filter((item) =>
          item.fieldSchlagwort.some((tag) =>
            tag.entity ? tag.entity.entityId === activeFilter.entityId : false
          )
        );
      } else if (activeFilter.length > 0) {
        // Filter all selected filters.
        filteredItems = filteredItems.filter((item) => {
          if (this.props.filterCloudMultipleFilterCondition === "OR") {
            for (const activeFilterItem of activeFilter) {
              if (item[activeFilterItem.field] == null) {
                continue;
              }
              // Filtering on string-based fields.
              if (typeof item[activeFilterItem.field] === "string") {
                if (
                  item[activeFilterItem.field] === activeFilterItem.entityId
                ) {
                  return true;
                }

                continue;
              }

              // Single Entity reference fields.
              if (item[activeFilterItem.field].entity) {
                if (
                  item[activeFilterItem.field].entity.entityId ===
                  activeFilterItem.entityId
                ) {
                  return true;
                }

                continue;
              }

              // Multi Entity reference fields.
              if (
                item[activeFilterItem.field].some(
                  (tag) =>
                    tag.entity &&
                    tag.entity.entityId === activeFilterItem.entityId
                )
              ) {
                return true;
              }
            }
          } else {
            for (const activeFilterItem of activeFilter) {
              if (item[activeFilterItem.field] == null) {
                return false;
              }
              // Filtering on string-based fields.
              if (typeof item[activeFilterItem.field] === "string") {
                if (
                  item[activeFilterItem.field] !== activeFilterItem.entityId
                ) {
                  return false;
                }

                continue;
              }

              // Single Entity reference fields.
              if (item[activeFilterItem.field].entity) {
                if (
                  item[activeFilterItem.field].entity.entityId !==
                  activeFilterItem.entityId
                ) {
                  return false;
                }

                continue;
              }

              // Multi Entity reference fields.
              if (
                !item[activeFilterItem.field].some(
                  (tag) =>
                    tag.entity &&
                    tag.entity.entityId === activeFilterItem.entityId
                )
              ) {
                return false;
              }
            }

            return true;
          }
        });
      }

      // Search.
      if (activeSearch !== "") {
        filteredItems = filteredItems.filter((item) => {
          const parsedItem = JSON.stringify(item).toLowerCase();

          return parsedItem.includes(activeSearch);
        });
      }
    }

    return filteredItems;
  };

  getItemsToShow = (start, end) => {
    if (
      !this.props.nodes.loading &&
      this.props.nodes.nodeQuery.entities.length >= 1
    ) {
      if (!end) {
        end = this.props.nodes.nodeQuery.entities.length;
      }

      const items = this.filterItems();

      if (this.props.usePager) {
        return items.slice(start, end);
      }

      return items;
    }

    return [];
  };

  constructor(props) {
    super(props);

    this.state = {
      itemsMatching: this.getItemsToShow(0),
      itemsToShow: this.getItemsToShow(
        0,
        this.props.usePager
          ? this.props.content.fieldElementeProSeite
          : null
      ),
      activeFilter: [],
      activeSearch: "",
      dayFilter: [],
      monthFilter: [],
      ferienFilter: false
    };

  }

  pushPagerFullPageConfig = () => {
    if (
      this.props.content.fieldPagerAufVollseiten &&
      this.props.nodes.nodeQuery
    ) {
      const pagerFullPagesConfig = {
        id: this.props.content.entityId,
        items: this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.content.fieldLinkZurUebersicht,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes.nodeQuery) !==
      JSON.stringify(this.props.nodes.nodeQuery)
    ) {
      this.setState({
        itemsMatching: this.getItemsToShow(0),
        itemsToShow: this.getItemsToShow(
          0,
          this.props.usePager
            ? this.props.content.fieldElementeProSeite
            : null
        ),
      });
      this.pushPagerFullPageConfig();
    }
  }

  /**
   * Reset all or specific filers.
   * @param {string} field Field to reset filters for
   */
  resetFilter = (field = "") => {
    // by default, all filters get resetted
    let updatedFilter = [];
    // if field is specified, only reset filters for this field
    if (!!field) {
      updatedFilter = this.state.activeFilter.filter((item) => {
        return item.field !== field;
      });
    }

    this.setState({ activeFilter: updatedFilter }, () =>
      this.setState({
        itemsMatching: this.getItemsToShow(0),
        itemsToShow: this.getItemsToShow(
          0,
          this.props.usePager
            ? this.props.content.fieldElementeProSeite
            : null
        ),
      })
    );
  };

  changeActiveFilter = (activeFilter) => {
    if (
      this.state.activeFilter &&
      this.state.activeFilter.filter(
        (item) =>
          item.entityId === activeFilter.entityId &&
          item.field === activeFilter.field
      ).length > 0
    ) {
      this.setState({ activeFilter: null }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.usePager
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      this.setState(
        {
          activeFilter: {
            ...this.state.activeFilter,
            activeFilter,
          },
        },
        () =>
          this.setState({
            itemsMatching: this.getItemsToShow(0),
            itemsToShow: this.getItemsToShow(
              0,
              this.props.usePager
                ? this.props.content.fieldElementeProSeite
                : null
            ),
          })
      );
    }
    history.replaceState(null, null, ' ');
  };

  changeActiveFilterCloud = (activeFilter) => {
    history.replaceState(null, null, ' ');

    // First check "only one filter at a time" option.
    if (this.props.filterCloudFilterSingle) {
      if (
        this.state.activeFilter.length > 0 &&
        this.state.activeFilter[0].entityId === activeFilter.entityId
      ) {
        this.setState({ activeFilter: [] }, () =>
          this.setState({
            itemsMatching: this.getItemsToShow(0),
            itemsToShow: this.getItemsToShow(
              0,
              this.props.usePager
                ? this.props.content.fieldElementeProSeite
                : null
            ),
          })
        );
      } else {
        this.setState(
          {
            activeFilter: [activeFilter],
          },
          () =>
            this.setState({
              itemsMatching: this.getItemsToShow(0),
              itemsToShow: this.getItemsToShow(
                0,
                this.props.usePager
                  ? this.props.content.fieldElementeProSeite
                  : null
              ),
            })
        );
      }

      return;
    }

    if (
      this.state.activeFilter &&
      this.state.activeFilter.filter(
        (item) =>
          item.entityId === activeFilter.entityId &&
          item.field === activeFilter.field
      ).length > 0
    ) {
      // Remove filter from active filters.
      const newActiveFilter = this.state.activeFilter.filter(
        (item) =>
          item.entityId !== activeFilter.entityId ||
          item.field !== activeFilter.field
      );
      this.setState({ activeFilter: newActiveFilter }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.usePager
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      // Add filter to active filters.
      this.setState(
        { activeFilter: [...this.state.activeFilter, activeFilter] },
        () =>
          this.setState({
            itemsMatching: this.getItemsToShow(0),
            itemsToShow: this.getItemsToShow(
              0,
              this.props.usePager
                ? this.props.content.fieldElementeProSeite
                : null
            ),
          })
      );
    }
  };

  changeActiveSearch = (searchValue) => {
    if (typeof searchValue === "undefined") {
      this.setState({ activeSearch: "" }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.usePager
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    } else {
      this.setState({ activeSearch: searchValue.toLowerCase() }, () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.usePager
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
      );
    }
  };

  // Filter is initialized and set in component-filter-cloud
  changeMonthFilter = (month) => {
    let monthFilter = [...this.state.monthFilter];

    if (month === "reset") {
      monthFilter = [];
    } else {
      if (monthFilter.indexOf(month) > -1) {
        // remove element
        monthFilter.splice(monthFilter.indexOf(month), 1);
      } else {
        // add element
        monthFilter = [...monthFilter, month];
      }
    }

    this.setState(
      {
        monthFilter,
      },
      () =>
        this.setState({
          itemsMatching: this.getItemsToShow(0),
          itemsToShow: this.getItemsToShow(
            0,
            this.props.usePager
              ? this.props.content.fieldElementeProSeite
              : null
          ),
        })
    );
  };

  // Filter is initialized and set in component-filter-cloud
  changeDayFilter = (day) => {
    let dayFilter = [...this.state.dayFilter];

    if (day === "reset") {
      dayFilter = [];
    } else {
      if (dayFilter.indexOf(day) > -1) {
        // remove element
        dayFilter.splice(dayFilter.indexOf(day), 1);
      } else {
        // add element
        dayFilter = [...dayFilter, day];
      }
    }
    this.setState({ dayFilter }, () =>
      this.setState({
        itemsMatching: this.getItemsToShow(0),
        itemsToShow: this.getItemsToShow(
          0,
          this.props.usePager
            ? this.props.content.fieldElementeProSeite
            : null
        ),
      })
    );
  };

  // Filter is initialized and set in component-filter-cloud
  changeFerienFilter = () => {
    let ferienFilter = !this.state.ferienFilter;

    this.setState({ ferienFilter }, () =>
      this.setState({
        itemsMatching: this.getItemsToShow(0),
        itemsToShow: this.getItemsToShow(
          0,
          this.props.usePager
            ? this.props.content.fieldElementeProSeite
            : null
        ),
      })
    );

  };

  handlePageClick = (data) => {
    this.setState(
      {
        itemsToShow: this.getItemsToShow(
          data.selected * this.props.content.fieldElementeProSeite,
          data.selected * this.props.content.fieldElementeProSeite +
            this.props.content.fieldElementeProSeite
        ),
      },
      () => scroll.scrollTo(this.itemSection.current.offsetTop)
    );
  };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-extended-teaser-overview": true,
      [`type-${this.props.content.fieldTypExtended}`]: true,
    });

    return (
      <section className={sectionClassNames} ref={this.itemSection}>
        {!this.props.nodes.loading &&
          !!this.props.nodes.nodeQuery.entities.length &&
          this.props.content.fieldInhaltstyp && (
            <ErrorBoundary>
              <ComponentFilterCloud
                items={this.props.nodes.nodeQuery.entities}
                filter={this.props.filterCloudFilters}
                labels={this.props.filterCloudLabels}
                activeFilter={this.state.activeFilter}
                filterItems={this.filterItems}
                preventEmptyResult={this.props.filterCloudPreventEmptyResult}
                changeActiveFilter={this.changeActiveFilterCloud}
                resetFilter={this.resetFilter}
                changeMonthFilter={this.changeMonthFilter}
                monthFilter={this.state.monthFilter}
                changeDayFilter={this.changeDayFilter}
                dayFilter={this.state.dayFilter}
                changeFerienFilter={this.changeFerienFilter}
                ferienFilter={this.state.ferienFilter}
                resetFilterOn={true}
                type={this.props.content.entityBundle}
                changeActiveSearch={this.changeActiveSearch}
              />
            </ErrorBoundary>
          )}
        {/*!this.props.nodes.loading &&
          this.props.nodes.nodeQuery.entities.length > 1 &&
          (this.props.content.fieldFilterwolke ||
            this.props.content.fieldInhaltstyp) &&
          this.props.content.fieldInhaltstyp != "kurs" && (
            <ErrorBoundary>
              <ComponentFilterCloud
                items={this.state.itemsMatching}
                filter={
                  this.props.content.fieldInhaltstyp === "kurs"
                    ? "fieldKurstyp"
                    : "fieldSchlagwort"
                }
                activeFilter={this.state.activeFilter}
                changeActiveFilter={this.changeActiveFilter}
              />
            </ErrorBoundary>
              )*/}

        {!this.props.nodes.loading &&
          this.props.nodes.nodeQuery.entities.length > 1 &&
          this.props.content.fieldFilterImText && (
            <ErrorBoundary>
              <ComponentExtendedTeaserOverviewFilterText
                items={this.props.content.fieldFilterImTextReference}
                filter="fieldSchlagwort"
                activeFilter={this.state.activeFilter}
                changeActiveFilter={this.changeActiveFilter}
              />
            </ErrorBoundary>
          )}

        {!this.props.nodes.loading &&
          this.props.nodes.nodeQuery.entities.length > 1 &&
          this.props.content.fieldFilterDialogBaum && (
            <ErrorBoundary>
              <ComponentExtendedTeaserOverviewFilterDialogBaum
                items={this.props.content.fieldFilterDialogBaumReferen}
                activeFilter={this.state.activeFilter}
                changeActiveFilter={this.changeActiveFilter}
              />
            </ErrorBoundary>
          )}

        {this.props.content.fieldInhaltstyp ? (
          <div
            className={
              this.props.content.fieldInhaltstyp === "kurs"
                ? "kursuebersicht"
                : "angebotsuebersicht"
            }
          >
            <div className="container legend-wrapper">
              <div className={`row`}>
                {this.props.content.fieldInhaltstyp === "kindergeburtstag" && (
                  <>
                    <div className="col-10 col-md-6 legend grow">Format</div>
                    <div className="col-10 col-md-2 legend">
                      Altersempfehlung
                    </div>
                    <div className="col-10 col-md-1 legend info">Info</div>
                  </>
                )}
                {this.props.content.fieldInhaltstyp === "angebot" && (
                  <>
                    <div className="col-10 col-md-1 legend">Nummer</div>
                    <div className="col-10 col-md-5 legend grow">Format</div>
                    <div className="col-10 col-md-3 legend">
                      Altersempfehlung
                    </div>
                    <div className="col-10 col-md-1 legend info">Info</div>
                  </>
                )}
                {this.props.content.fieldInhaltstyp === "kurs" && (
                  <>
                    <div className="col-10 col-md-2 legend">Tag, Datum</div>
                    <div className="col-10 col-md-1 col-lg-3 legend">Format</div>
                    <div className="col-10 col-md-4 col-lg-3 legend">Uhrzeit</div>
                    <div className="col-10 col-md-2 col-lg-1 legend">
                      Altersempfehlung
                    </div>
                    <div className="col-10 col-md-1 legend">Info</div>
                  </>
                )}
              </div>
            </div>
            <div className="container">
              <div className={`row`}>
                {this.state.itemsToShow.length >= 1 &&
                  this.props.content.fieldInhaltstyp !== "kurs" &&
                  this.state.itemsToShow.map((item, index) => (
                    <React.Fragment key={index}>
                      {(() => {
                        switch (item?.entityBundle) {
                          case "kindergeburtstag":
                            return (
                              <div className="col-10 teaser-wrapper">
                                <ErrorBoundary>
                                  <TeaserKindergeburtstag
                                    item={item}
                                    pagerFullPage={
                                      this.props.content.fieldPagerAufVollseiten
                                        ? this.props.content.entityId
                                        : false
                                    }
                                  />
                                </ErrorBoundary>
                              </div>
                            );
                          case "angebot":
                            return (
                              <div className="col-10 teaser-wrapper">
                                <ErrorBoundary>
                                  <TeaserAngebot
                                    item={item}
                                    pagerFullPage={
                                      this.props.content.fieldPagerAufVollseiten
                                        ? this.props.content.entityId
                                        : false
                                    }
                                  />
                                </ErrorBoundary>
                              </div>
                            );
                          default:
                            return null;
                        }
                      })()}
                    </React.Fragment>
                  ))}
              </div>
            </div>
            {this.props.content.fieldInhaltstyp === "kurs" && (
              <div className="item-wrapper">
                <KursOverview
                  itemsToShow={this.state.itemsToShow}
                  content={this.props.content}
                  usePager={true}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="container">
            {this.props.content.fieldTypExtended === "person" ? (
              this.state.itemsToShow.length >= 1 && (
                <div className="row">
                  <div className="col-10 col-lg-8 offset-lg-2">
                    {this.props.content.fieldShowTitle &&
                      (this.props.content.fieldTitle ? (
                        <h2>{this.props.content.fieldTitle}</h2>
                      ) : (
                        this.props.content.fieldSchlagwort && (
                          <h2>
                            {this.props.content.fieldSchlagwort
                              .map((tag) => tag.entity.entityLabel)
                              .join(" & ")}
                          </h2>
                        )
                      ))}
                    {this.state.itemsToShow && this.state.itemsToShow.length >= 1 &&
                      <PersonOverviewAccordions
                        itemsToShow={this.state.itemsToShow}
                        location={this.props.location}
                      />
                    }
                  </div>
                </div>
              )
            ) : (
              <Masonry className={`row`}>
                {this.state.itemsToShow.length >= 1 &&
                  this.state.itemsToShow.map((item, index) => (
                    <React.Fragment key={index}>
                      {(() => {
                        switch (item?.entityBundle) {
                          case "news":
                            return (
                              <div className="col-10 col-md-4 offset-md-1">
                                <ErrorBoundary>
                                  <TeaserNews
                                    item={item}
                                    pagerFullPage={
                                      this.props.content.fieldPagerAufVollseiten
                                        ? this.props.content.entityId
                                        : false
                                    }
                                  />
                                </ErrorBoundary>
                              </div>
                            );
                          case "projekt":
                            return (
                              <div
                                className={classNames({
                                  "col-10 col-md-custom": true,
                                })}
                              >
                                <ErrorBoundary>
                                  <TeaserProjekt
                                    item={item}
                                    pagerFullPage={
                                      this.props.content.fieldPagerAufVollseiten
                                        ? this.props.content.entityId
                                        : false
                                    }
                                  />
                                </ErrorBoundary>
                              </div>
                            );

                          case "veranstaltung":
                            return <TeaserEvent item={item} />;

                          case "highlight": {
                            if (item.status) {
                              return (
                                <div className="col-10 col-md-custom teaser-wrapper">
                                  <ErrorBoundary>
                                    <TeaserHighlight item={item} />
                                  </ErrorBoundary>
                                </div>
                              );
                            }
                          }

                          default:
                            return null;
                        }
                      })()}
                    </React.Fragment>
                  ))}
              </Masonry>
            )}
          </div>
        )}

        {this.props.content.entityBundle === "kurse_uebersicht" &&
          <ScrollToTop/>
        }

        {!this.props.nodes.loading &&
          this.props.nodes.nodeQuery.entities.length >
            this.props.content.fieldElementeProSeite &&
          this.props.usePager && (
            <div className="container">
              <div className="row">
                <div className="col-10">
                  <div className="pagination">
                    <ErrorBoundary>
                      <ReactPaginate
                        onPageChange={this.handlePageClick}
                        pageCount={Math.ceil(
                          this.state.itemsMatching.length /
                            this.props.content.fieldElementeProSeite
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        previousLabel={"Vorherige Seite"}
                        nextLabel={"Nächste Seite"}
                        previousClassName="btn btn-primary previous"
                        nextClassName="btn btn-primary next"
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              </div>
            </div>
          )}
      </section>
    );
  }
}

ParagraphExtendedTeaserOverview.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldTypExtended: PropTypes.oneOf([
      "news",
      "person",
      "projekt",
      "veranstaltung",
      "highlight",
    ]),
    fieldPagerVerwenden: PropTypes.bool,
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityBundle: PropTypes.oneOf([
            "filtertext_text",
            "filtertext_filter",
          ]),
          fieldFilterTextText: PropTypes.string,
          fieldFilter: PropTypes.shape({
            entity: PropTypes.shape({
              entityLabel: PropTypes.string,
              entityId: PropTypes.string,
            }),
          }),
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityId: PropTypes.string,
          entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
          fieldFilterTextText: PropTypes.string,
          fieldFilterMultiple: PropTypes.arrayOf(
            PropTypes.shape({
              targetId: PropTypes.string,
              entity: PropTypes.shape({
                entityLabel: PropTypes.string,
              }),
            })
          ),
        }),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
  }),
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.object.isRequired,
  skipQuery: PropTypes.bool,
};

/* TODO: Create own components for extended teaser paragraphs and add own
 *   queries for each component to prevent this chaos.
 * TODO: Refactor all teaserNodeQueries - work with fragments and just make
 *  the conditions and sorting unique for the queries. */
export default compose(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) =>
      props.content.fieldManuelleSortierung ||
      props.content.fieldTypExtended === "veranstaltung" ||
      props.skipQuery,
    options: (props) => ({
      variables: {
        limit: 1000,
        type: [
          props.content.fieldInhaltstyp
            ? props.content.fieldInhaltstyp
            : props.content.fieldTypExtended,
        ],
        tag: props.content.fieldSchlagwort
          ? props.content.fieldSchlagwort.map((item) =>
              item.targetId.toString()
            )
          : false,
        filterTagEnabled:
          props.content.fieldSchlagwort &&
          props.content.fieldSchlagwort.length > 0,
        department:
          props.content.fieldDepartment &&
          props.content.fieldDepartment.targetId.toString(),
      },
    }),
  }),
  graphql(teaserNodeQueryEventTagFiltered, {
    name: "nodes",
    skip: (props) =>
      props.content.fieldTypExtended !== "veranstaltung" ||
      props.content.fieldManuelleSortierung ||
      props.skipQuery,
    options: (props) => ({
      variables: {
        date: moment().format("YYYY-MM-DD"),
        limit: 10000,
        type: [props.content.fieldTypExtended],
        tags: props.content.fieldSchlagwort.map((item) =>
          item.targetId.toString()
        ),
        filterTagEnabled: props.content.fieldSchlagwort.length > 0,
      },
    }),
  })
)(connect()(ParagraphExtendedTeaserOverview));
