import React from "react";
import PropTypes from "prop-types";

const ModalHeader = (props) => {
  return (
    <section className="modal-header">
      {props.onPrint &&
        <div className="modal-actions modal-actions-left">
          {props.onPrint &&
            <button className="print">
              <img src="/ui-icons/icon_printer.svg" alt="Drucken" />
            </button>
          }
        </div>
      }
      <div className="modal-title">
        <span>{props.title}</span>
      </div>
      <div className="modal-actions modal-actions-right">
        <button className="modal-close" onClick={props.onClose}>
          <img src="/ui-icons/icon_cross.svg" alt="Dialog schließen" />
        </button>
      </div>
    </section>
  )
}

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrint: PropTypes.func,
}

export default ModalHeader;