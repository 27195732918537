import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import LoadingIndicator from "../../../loading-indicator";

class ParagraphNewsletteranmeldung extends Component {

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-newsletter-register": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-10">
              <iframe
                src="https://login.mailingwork.de/-optin-form/33505/1/dP4JR"
                title="Newsletteranmeldung JKS Dresden"
                width="100%"
                height="1200px"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphNewsletteranmeldung.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
  }),
};

export default ParagraphNewsletteranmeldung;
