import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserPersonOverlay from "../../../teaser-base/person/teaser-person-overlay";
import ErrorBoundary from "../../../../error-boundary";

/**
 * Usually the first module on an person page - person intro module with most
 * important information.
 */
class ParagraphPersonenInformationen extends Component {
  state = {};

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-person-information": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-10 col-md-14 offset-md-1">
              <ErrorBoundary>
                <TeaserPersonOverlay item={this.props.nodeContent} />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphPersonenInformationen.propTypes = {
  content: PropTypes.object,
  nodeContent: PropTypes.shape({})
};

export default ParagraphPersonenInformationen;
