import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { encodeUrl } from "../../../../../lib/encode-url";
import Image from "../../../../image/image";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});


const PersonOverviewAccordions = ({ itemsToShow, location, adminApp }) => {
  let preExpanded = [];
  const teaserWrapper = useRef();

  itemsToShow.map(item => {
    if (encodeUrl(item.entityLabel) === location.hash.replace("#", "")) {
      preExpanded = [item.entityId];
    }
  });

  const [preExpandedItem, setPreExpandedItem] = useState(
    preExpanded
  );

  const updateAccordion = (uuid) => {
    let element = teaserWrapper.current.querySelector(`h3[data-uuid="${uuid}"]`);

    let scrollPosition = element?.getBoundingClientRect().top + window.scrollY - 300;

    if (element) {
      history.pushState(null, window.location.pathname, `#${encodeUrl(element.innerHTML)}`);
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  // useLayoutEffect fires before the browser repaints the screen
  // https://react.dev/reference/react/useLayoutEffect
  useLayoutEffect(() => {
    // check if location hash matches item hash
    // if so, store in preExpandedItem
    if (location) {
      itemsToShow.map(item => {
        if (encodeUrl(item.entityLabel) === location.hash.replace("#", "")) {
          setPreExpandedItem([item.entityId]);
          updateAccordion(item.entityId);
        }
      });
    }
  }, [location.hash]);

  return (
    <div
      className="person-teasers"
      ref={teaserWrapper}
    >
      <React.Suspense fallback={<div />}>
        <Accordion
          allowZeroExpanded
          preExpanded={preExpandedItem}
          onChange={(uuid) => updateAccordion(uuid)}
        >
          {itemsToShow.map((item) => (
            <AccordionItem uuid={item.entityId} key={item.entityId}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className="person-teaser-header">
                    <h3 id={encodeUrl(item.title)} data-uuid={item.entityId}>{item.title}</h3>
                    {(item.fieldRolleOderFunktion || item.fieldDepartment) && (
                      <h4>
                        {item.fieldRolleOderFunktion &&
                          <span>{item.fieldRolleOderFunktion.entity.name}</span>
                        }
                      </h4>
                    )}
                  </div>
                  <span className="inktrap icon">↓</span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="accordion-panel-wrapper image-left">
                  {item.fieldBildWCaption && (
                    <div className="image-wrapper">
                      <Image
                        caption={
                          !item.fieldBildWCaption.defaultCaption ? item.fieldBildWCaption.customCaption : null
                        }
                        data={item.fieldBildWCaption.entity.fieldMediaImage}
                      />
                    </div>
                  )}
                  <div className="text-wrapper">
                    {item.fieldBeschreibung && (
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: item.fieldBeschreibung.processed,
                        }}
                      />
                    )}
                    <div className="contact-details">
                      {item.fieldTelefon && (
                        <p className="phone">{item.fieldTelefon}</p>
                      )}
                      {item.fieldMobil && (
                        <p className="mobile">{item.fieldMobil}</p>
                      )}
                      {item.fieldMail && (
                        <p className="mail">
                          <a href={"mailto:" + item.fieldMail}>
                            {item.fieldMail}
                          </a>
                        </p>
                      )}
                      {item.fieldDepartment && (
                        <p className="department">
                          <span className="department-value">
                            {item.fieldDepartment.entity.name}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </React.Suspense>
    </div>
  );
};

export const personOverviewAccordionsPropTypes = PropTypes.shape({
  itemsToShow: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
});

PersonOverviewAccordions.propTypes = {
  itemsToShow: PropTypes.object.isRequired,
  adminApp: PropTypes.bool.isRequired,
  item: personOverviewAccordionsPropTypes,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(PersonOverviewAccordions));
