import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";

// Router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Page Components
import Landingpage from "./landingpage/landingpage";
import Event from "./event/event";
import Person from "./person/person";
import footerConfigQuery from "footer-config-query.graphql";
import PageFooter from "./footer/page-footer";
import menuQuery from "./general-components/menu-query.graphql";
import SimpleHeader from "./header/simple-header";
import ErrorBoundary from "./error-boundary";
import NodeRevision from "./backend/NodeRevision";
import {self} from "./config";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({ reduxStore });

/**
 * @todo 404.
 * @todo Fetch Page title and push to store. Use for <title> tag.
 */
class App extends Component {
  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  componentDidMount() {
    document.body.className += `${this.generateMainClasses(
      this.props.location.pathname
    )}`;
  }

  componentDidUpdate(prevProps) {
    // Scroll to top on route change
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      window.scrollTo(0, 0);
      document.body.className = `${this.generateMainClasses(
        this.props.location.pathname
      )}`;
    }
  }

  render() {
    console.log(this.props.reduxStore);
    return (
      <div className="main-content" data-special-design={this.props.reduxStore.appStore.specialDesign}>
        <header id="site-header">
          <ErrorBoundary>
            <SimpleHeader footerConfig={this.props.footerConfig.configPagesById} footerMenu={this.props.menuQuery.menuByName}/>
          </ErrorBoundary>
        </header>

        <main className="main-page-content">
          <ErrorBoundary>
            <Switch>
              <Route exact path="/:alias" component={Landingpage} />
              <Route exact path="/projekt/:alias" component={Landingpage} />
              <Route exact path="/news/:alias" component={Landingpage} />
              <Route exact path="/veranstaltung/:alias" component={Event} />
              <Route exact path="/person/:alias" component={Person} />
              <Route
                exact
                path="/node/:nodeId/revisions/:revisionId/view"
                component={NodeRevision}
              />
              <Route exact path="/" component={Landingpage} />
            </Switch>
          </ErrorBoundary>
          <div id="modal-wrapper"></div>
        </main>

        <footer>
          <div className="main-footer" id="pageFooter">
            <ErrorBoundary>
              <PageFooter
                footerConfig={this.props.footerConfig.configPagesById}
                menu={this.props.menuQuery.menuByName}
              />
            </ErrorBoundary>
          </div>
          <div className="partner-footer">
            <div className="container">
              <div className="row">
                <div className="col-10 col-md-5 text-wrapper">
                  <div className="footer-text">Die Jugendkunstschule Dresden als Kultureinrichtung der Landeshauptstadt Dresden (Kulturraum) wird mitfinanziert durch Steuermittel auf der Grundlage des vom Sächsischen Landtag beschlossenen Haushaltes.</div>
                </div>
                <div className="col-10 col-md-3 offset-md-2 logo-wrapper">
                  <img src={`${self}/footer-logos.svg`} className="partner-logos" alt={"Logo der Stadt Dresden und des Bundesland Sachsens"}/>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  footerConfig: PropTypes.object.isRequired,
  menuQuery: PropTypes.object.isRequired,
  reduxStore: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default compose(
  graphql(footerConfigQuery, {
    name: "footerConfig",
  }),
  graphql(menuQuery, {
    options: {
      variables: { name: "footer" },
    },
    name: "menuQuery",
  })
)(withRouter(connect(mapStateToProps)(App)));
