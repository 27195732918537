import React, { Component } from "react";
import PropTypes from "prop-types";
import { Search, Button } from "carbon-components-react";

class ComponentExtendedTeaserOverviewSearch extends Component {
  state = {};

  search = React.createRef();

  render() {
    return (
      <section className="search">
        <Search
          ref={this.search}
          id="search-1"
          placeHolderText="Suchen & Finden"
          size="xl"
          onChange={(event) =>
            typeof event.target.value === "undefined" ||
            event.target.value.length < 3
              ? this.props.changeActiveSearch()
              : this.props.changeActiveSearch(
                this.search.current.input.value
              )
          }
          labelText="Suche..."
        />
        <Button
          className="sr-only"
          onClick={() =>
            this.props.changeActiveSearch(this.search.current.input.value)
          }
        >
          Suchen
        </Button>
      </section>
    );
  }
}

ComponentExtendedTeaserOverviewSearch.propTypes = {
  changeActiveSearch: PropTypes.func.isRequired,
};

export default ComponentExtendedTeaserOverviewSearch;
