import React from "react";
import ErrorFallBack from "react-ssr-error-boundary";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: "",
    errorInfo: "",
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);
    // You can also log the error to an error reporting service
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-16">
              <ErrorFallBack>
                <h1>Hier stimmt was nicht</h1>
                <pre>{this.state.error.message}</pre>
                {this.state.errorInfo.componentStack && (
                  <pre>{this.state.errorInfo.componentStack}</pre>
                )}
              </ErrorFallBack>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
