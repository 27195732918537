import React from "react";
import ScrollUpButton from "react-scroll-up-button";

const ScrollToTop = (props) => {

  return(
    <ScrollUpButton
      StopPosition={0}
      ShowAtPosition={1500}
      EasingType='easeOutCubic'
      AnimationDuration={500}
      ContainerClassName={"to-top"}
      TransitionClassName={"show"}
    >
      <button className="inktrap icon">
        ↑
      </button>
    </ScrollUpButton>
  )
}

export default ScrollToTop;