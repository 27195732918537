import React, { Component } from "react";
import PropTypes from "prop-types";
import {Link as ReactRouterLink, withRouter} from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../../../backend/edit-button";
import FieldTime from "../../../../date-time/field-time";
import Image from "../../../../image/image";
import Link from "../../../../link/link";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class SlideContent extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article className="slide-content">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        {/*this.props.item.entityBundle === "news" &&
          <div className={"meta-wrapper"}>
            Meldung vom
            <FieldTime
              date={this.props.item.created}
              timestamp={true}
              format={"DD.MM.YYYY"}
            />
          </div>
        */}
        {this.props.item.entityLabel &&
          <h3>
            {this.props.item.entityLabel}
          </h3>
        }
        {this.props.item.fieldTeasertext &&
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: this.props.item.fieldTeasertext.processed,
            }}
          />
        }
        {this.props.item.fieldLink &&
          <div className="link-wrapper">
            {this.props.item.fieldLink.url.routed ? (
              <ReactRouterLink to={this.props.item.fieldLink.url ? this.props.item.fieldLink.url.path : this.props.item.fieldLink.entityUrl.path}>
                {this.props.item.fieldLink.title ? this.props.item.fieldLink.title : "mehr erfahren"}
              </ReactRouterLink>
            ) : (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.item.fieldLink.url ? this.props.item.fieldLink.url.path : this.props.item.fieldLink.entityUrl.path}
              >
                {this.props.item.fieldLink.title ? this.props.item.fieldLink.title : "mehr erfahren"}
              </a>
            )}
            <span className="inktrap">→</span>
          </div>
        }
      </article>
    );
  }
}

export const slideContentPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  fieldTeasertext: PropTypes.shape({
    processed: PropTypes.string,
  }),
});

SlideContent.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: slideContentPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(SlideContent));
