import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Collapse } from "react-collapse";
import LazyLoad from "react-lazyload";
import EditButton from "../../../backend/edit-button";
import TeaserKursDate from "./teaser-kurs-date";
import TeaserKursTime from "./teaser-kurs-time";
import TeaserKursDetailedInformation from "./teaser-kurs-detailed-information";
import moment from "moment-timezone";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { encodeUrl } from "../../../lib/encode-url";
import { updateAccordion } from "../../../lib/update-accordion";

moment.tz.setDefault("Europe/Berlin");

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

const DateFormat = "dd, DD.MM.";
const DateFormatShort = "DD.MM.YYYY";
const TimeFormat = "HH:mm";

const TeaserKurs = ({ item, location, adminApp }) => {
  const [open, setOpen] = useState(false);

  const teaser = useRef();

  // format item title to item hash for comparison
  const encodedTitle = encodeUrl([item.title, item.entityId].join("-"));
  const itemHash = `#${encodedTitle}`;

  const [preExpandedItem, setPreExpandedItem] = useState(
    location.hash === itemHash ? [item.entityId] : []
  );

  // useLayoutEffect fires before the browser repaints the screen
  // https://react.dev/reference/react/useLayoutEffect
  useLayoutEffect(() => {
    // check if location hash matches item hash
    // if so, store in preExpandedItem
    if (location.hash === itemHash) {
      setPreExpandedItem([item.entityId]);
      // scroll animation
      updateAccordion(
        [item.title, item.entityId].join("-"),
        teaser.current,
        false
      );
    }
  }, [location.hash, itemHash, item.entityId]);

  const getAltersempfehlung = () => {
    if (
      item.fieldAlterVon &&
      item.fieldAlterVon == 0 &&
      item.fieldAlterBis &&
      item.fieldAlterBis == 0
    ) {
      return "für alle";
    }

    if (item.fieldAlterBis && item.fieldAlterBis != 0) {
      return `${item.fieldAlterVon} — ${item.fieldAlterBis}`;
    }

    if (item.fieldAlterVon && item.fieldAlterVon != 0) {
      return `ab ${item.fieldAlterVon}`;
    }
  };

  const firstDate =
    item.fieldTermine.length > 0 && item.fieldTermine[0].entity != null
      ? moment(
          item.fieldTermine[0].entity.fieldDatumKursTermin.value,
          "YYYY-MM-DDTHH:mm:ss"
        )
      : moment(item.fieldDatumKurs.value, "YYYY-MM-DDTHH:mm:ss");

  const isWorkshop = item.fieldVeranstaltungsart === "Workshop";
  const isCompact = item.fieldVeranstaltungsart === "Kompaktkurs";

  const hideBooking = ((isWorkshop || isCompact) && moment().isAfter(firstDate)),
    hideAvailablePlaces = item.fieldVeranstaltungsart === "Ferien" || item.fieldVeranstaltungsart === "Cafe" || item.fieldVeranstaltungsart === "Veranstaltung" || item.fieldVeranstaltungsart === "Kino";

  const futureAppointments = item.fieldTermine
    .filter((termin) => termin.entity != null)
    .filter((termin) =>
      moment(termin.entity.fieldDatumKursTermin.value).isSameOrAfter(
        moment(),
        "day"
      )
    );

  return (
    <article
      className={`node node-${item.entityId} node-teaser node-teaser-default teaser-kurs col-10`}
      id={encodedTitle}
      ref={teaser}
    >
      <EditButton
        adminApp={adminApp}
        entityId={item.entityId}
        destinationRoute={location.pathname}
      />
      <div className="container">
        <React.Suspense fallback={<div />}>
          <Accordion
            allowZeroExpanded
            onChange={() =>
              updateAccordion(
                [item.title, item.entityId].join("-"),
                teaser.current,
                preExpandedItem
              )
            }
            preExpanded={preExpandedItem}
          >
            <AccordionItem uuid={item.entityId}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className="row list-teaser">
                    <div className="col-3 col-md-2 teaser-kurs-date">
                      <TeaserKursDate
                        item={item}
                        dateFormat={DateFormat}
                        futureAppointments={futureAppointments}
                      />
                    </div>
                    <div className="col-6 col-md-5 col-lg-3 teaser-kurs-title">
                      <h2>{item.title}</h2>
                      {item.fieldSchlagwort.length > 0 ? (
                        <div className="tags">
                          {item.fieldSchlagwort.map((tag, index) => (
                            <small className="tag" key={index}>
                              ↗ {tag.entity.entityLabel}
                            </small>
                          ))}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-6 offset-3 offset-md-2 offset-lg-0 col-lg-3 teaser-kurs-time">
                      <TeaserKursTime
                        item={item}
                        timeFormat={TimeFormat}
                        setOpen={setOpen}
                        open={open}
                        hideBooking={hideBooking}
                        hideAvailablePlaces={hideAvailablePlaces}
                        futureAppointments={futureAppointments}
                      />
                    </div>
                    <div className="col-6 offset-3 offset-md-0 col-md-2 col-lg-1 age-recommendation">
                      {getAltersempfehlung()}
                    </div>
                    <div className="col-1 teaser-kurs-collapse">
                      <div className={"arrow inktrap"}>↓</div>
                    </div>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <LazyLoad>
                  <TeaserKursDetailedInformation
                    item={item}
                    dateFormat={DateFormat}
                    timeFormat={TimeFormat}
                    hideBooking={hideBooking}
                    hideAvailablePlaces={hideAvailablePlaces}
                    futureAppointments={futureAppointments}
                  />
                </LazyLoad>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </React.Suspense>
      </div>
    </article>
  );
};

export const teaserKursPropTypes = PropTypes.shape({
  entityId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  fieldAdresse: PropTypes.shape({
    additionalName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    administrativeArea: PropTypes.string,
    countryCode: PropTypes.string,
    dependentLocality: PropTypes.string,
    familyName: PropTypes.string,
    givenName: PropTypes.string,
    locality: PropTypes.string,
    organization: PropTypes.string,
    postalCode: PropTypes.string,
    sortingCode: PropTypes.string,
  }),
  fieldAnmeldeschluss: PropTypes.shape({
    value: PropTypes.string,
  }),
  fieldDatumKurs: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldGebuehr: PropTypes.shape({
    number: PropTypes.string,
    formatted: PropTypes.string,
    currencyCode: PropTypes.string,
  }),
  fieldHauptkurs: PropTypes.shape({
    targetId: PropTypes.number,
    entity: PropTypes.shape({
      title: PropTypes.string,
      entityUrl: PropTypes.shape({
        path: PropTypes.string,
      }),
      fieldDatumKurs: PropTypes.shape({
        value: PropTypes.string,
        endValue: PropTypes.string,
      }),
    }),
  }),
  fieldKurse: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.number,
      entity: PropTypes.shape({
        title: PropTypes.string,
        entityUrl: PropTypes.shape({
          path: PropTypes.string,
        }),
        fieldDatumKurs: PropTypes.shape({
          value: PropTypes.string,
          endValue: PropTypes.string,
        }),
      }),
    })
  ),
  fieldKursleitung: PropTypes.string,
  fieldKursnummer: PropTypes.string,
  fieldKurstyp: PropTypes.string,
  fieldOrt: PropTypes.string,
  fieldDauerAusblenden: PropTypes.bool,
  fieldText: PropTypes.shape({
    processed: PropTypes.string.isRequired,
  }),
  fieldFreiePlaetze: PropTypes.number,
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
});

TeaserKurs.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  item: teaserKursPropTypes,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserKurs));
