import React from "react";
import moment from "moment";

const TeaserKursDate = ({ item, dateFormat, futureAppointments }) => {
  const isCafe =
    item.fieldKurstyp === "Einzelveranstaltung" &&
    item.fieldVeranstaltungsart === "Cafe";

  const sameDay = moment(item.fieldDatumKurs.value).isSame(
    item.fieldDatumKurs.endValue,
    "day"
  );

  if (isCafe && futureAppointments.length > 0) {
    return (
      <span className="time-element">
        {moment
          .utc(futureAppointments[0].entity.fieldDatumKursTermin?.value)
          .local()
          .format(dateFormat)}
      </span>
    );
  }

  if (sameDay) {
    return (
      <span className="time-element">
        {moment.utc(item.fieldDatumKurs.value).local().format(dateFormat)}
      </span>
    );
  }

  return (
    <>
      <span className="time-element">
        {moment(item.fieldDatumKurs.value).isBefore(moment(), "day") &&
        futureAppointments.length > 0 ? (
          <>
            {moment
              .utc(futureAppointments[0].entity.fieldDatumKursTermin.value)
              .local()
              .format(dateFormat)}{" "}
          </>
        ) : (
          <>
            {moment.utc(item.fieldDatumKurs.value).local().format(dateFormat)}
          </>
        )}
        — {"  "}
      </span>
      <span className="time-element">
        {moment.utc(item.fieldDatumKurs.endValue).local().format(dateFormat)}
      </span>
    </>
  );
};

export default TeaserKursDate;
