import React from "react";
import PropTypes from "prop-types";
import { Link as ReactRouterLink } from "react-router-dom";

/**
 * Use this to render links from Drupal - internal and external links are
 * differentiated.
 */
const Link = (props) => {
  let content = props.link.title || props.children || props.link.entityLabel;
  return (
    <>
      {(props.link.url || props.link.entityUrl) && (props.link.entityUrl?.routed || props.link.url?.routed) ? (
        <ReactRouterLink
          className={props.className}
          to={props.link.url ? props.link.url.path : props.link.entityUrl.path}
        >
          {content}
        </ReactRouterLink>
      ) : (
        <a
          className={props.className}
          target="_blank"
          rel="noopener noreferrer"
          href={
            props.link.url ? props.link.url.path : props.link.entityUrl.path
          }
        >
          {content}
        </a>
      )}
    </>
  );
};

export const LinkPropType = PropTypes.shape({
  url: PropTypes.shape({
    routed: PropTypes.bool,
    path: PropTypes.string,
  }),
  title: PropTypes.string,
});

Link.propTypes = {
  className: PropTypes.string,
  link: LinkPropType.isRequired,
  children: PropTypes.element
};

export default Link;
