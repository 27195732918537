import React, { Component } from "react";
import PropTypes from "prop-types";
import { Select, SelectItem } from "carbon-components-react";

class ComponentExtendedTeaserOverviewFilterDialogBaum extends Component {
  state = {};

  render() {
    return (
      <section className="filter-dialog-baum">
        <div className="container">
          <div className="row">
            <div className="col-10 col-md-15 offset-md-1">
              {this.props.items.map((item, index) =>
                (() => {
                  switch (item.entity.entityBundle) {
                    case "filtertext_text":
                      return (
                        <p key={index}>{item.entity.fieldFilterTextText}</p>
                      );
                    case "filteroptionen":
                      return (
                        <Select
                          key={index}
                          id={item.entity.entityId}
                          hideLabel={true}
                          noLabel={true}
                          inline={false}
                          defaultValue="none"
                          value={
                            this.props.activeFilter
                              ? this.props.activeFilter.entityId
                              : "none"
                          }
                          size={"xl"}
                          onChange={(event) =>
                            event.target.value === "none"
                              ? this.props.changeActiveFilter({
                                  entityId: "none",
                                })
                              : this.props.changeActiveFilter(
                                  item.entity.fieldFilterMultiple.filter(
                                    (tag) => tag.targetId == event.target.value
                                  )[0].entity
                                )
                          }
                        >
                          <SelectItem value="none" text="Choose an option" />
                          {item.entity.fieldFilterMultiple.map(
                            (tag, tagIndex) => (
                              <SelectItem
                                value={tag.targetId}
                                text={tag.entity.entityLabel}
                                key={tagIndex}
                              />
                            )
                          )}
                        </Select>
                      );
                    default:
                      return null;
                  }
                })()
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ComponentExtendedTeaserOverviewFilterDialogBaum.propTypes = {
  activeFilter: PropTypes.shape({
    entityId: PropTypes.string,
  }),
  changeActiveFilter: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        entityId: PropTypes.string,
        entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
        fieldFilterTextText: PropTypes.string,
        fieldFilterMultiple: PropTypes.arrayOf(
          PropTypes.shape({
            targetId: PropTypes.string,
            entity: PropTypes.shape({
              entityLabel: PropTypes.string,
            }),
          })
        ),
      }),
    })
  ),
};

export default ComponentExtendedTeaserOverviewFilterDialogBaum;
