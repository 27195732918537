import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";

import ParagraphExtendedTeaserOverview from "./../paragraph-extended-teaser-overview";
import teaserNodeQueryTagFilteredSortAlphabeticaly from "../../../../teaser-base/queries/teaser-node-query-tag-filtered-sort-alphabetical.graphql";

class ParagraphExtendedTeaserOverviewProjects extends Component {
  render() {
    return (
      <ParagraphExtendedTeaserOverview
        content={this.props.content}
        usePager={false}
        nodes={this.props.nodes}
        skipQuery={true}
      />
    );
  }
}

ParagraphExtendedTeaserOverviewProjects.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldTypExtended: PropTypes.oneOf([
      "news",
      "person",
      "projekt",
      "veranstaltung",
    ]),
    fieldPagerVerwenden: PropTypes.bool,
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityBundle: PropTypes.oneOf([
            "filtertext_text",
            "filtertext_filter",
          ]),
          fieldFilterTextText: PropTypes.string,
          fieldFilter: PropTypes.shape({
            entity: PropTypes.shape({
              entityLabel: PropTypes.string,
              entityId: PropTypes.string,
            }),
          }),
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityId: PropTypes.string,
          entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
          fieldFilterTextText: PropTypes.string,
          fieldFilterMultiple: PropTypes.arrayOf(
            PropTypes.shape({
              targetId: PropTypes.string,
              entity: PropTypes.shape({
                entityLabel: PropTypes.string,
              }),
            })
          ),
        }),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
  }),
  nodes: PropTypes.object.isRequired,
};

export default graphql(teaserNodeQueryTagFilteredSortAlphabeticaly, {
  name: "nodes",
  skip: (props) =>
    !props.content.fieldTypExtended || props.content.fieldManuelleSortierung,
  options: (props) => ({
    variables: {
      limit: 10000,
      type: [props.content.fieldTypExtended],
      tags: props.content.fieldSchlagwort.map((item) =>
        item.targetId.toString()
      ),
      filterTagEnabled: props.content.fieldSchlagwort.length > 0,
    },
  }),
})(connect()(ParagraphExtendedTeaserOverviewProjects));
