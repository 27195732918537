import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import moment from "moment-timezone";

moment.tz.setDefault("Europe/Berlin");

import teaserNodeQueryKursFilterTag from "../../../../teaser-base/queries/teaser-node-query-kurs-tag-filtered.graphql";
import ParagraphExtendedTeaserOverview from "../paragraph-extended-teaser-overview";

class ParagraphExtendedTeaserOverviewKurs extends Component {
  /**
   * Sort kurse per date of referenced Termine (fieldTermine), not the base
   * date field (fieldDatumKurs).
   *
   * @returns {*[]}
   */
  sortNodes = () => {
    return this.props.nodes.nodeQuery
      ? [...this.props.nodes.nodeQuery.entities].sort((a, b) => {
          const futureAppointmentsA = a.fieldTermine
            .filter((termin) => termin.entity != null)
            .filter((termin) =>
              moment(termin.entity.fieldDatumKursTermin.value).isSameOrAfter(
                moment(),
                "day"
              )
            );

          const futureAppointmentsB = b.fieldTermine
            .filter((termin) => termin.entity != null)
            .filter((termin) =>
              moment(termin.entity.fieldDatumKursTermin.value).isSameOrAfter(
                moment(),
                "day"
              )
            );

          return (
            moment(
              futureAppointmentsA.length > 0
                ? futureAppointmentsA[0].entity.fieldDatumKursTermin.value
                : a.fieldDatumKurs.value
            ).format("YYYYMMDD") -
            moment(
              futureAppointmentsB.length > 0
                ? futureAppointmentsB[0].entity.fieldDatumKursTermin.value
                : b.fieldDatumKurs.value
            ).format("YYYYMMDD")
          );
        })
      : [];
  };

  state = {
    nodes: Object.assign({}, this.props.nodes, {
      nodeQuery: {
        entities: this.sortNodes(),
      },
    }),
  };

  componentDidMount() {
    this.setState({
      nodes: Object.assign({}, this.props.nodes, {
        nodeQuery: {
          entities: this.sortNodes(),
        },
      }),
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.nodes.loading &&
      !this.props.nodes.loading &&
      this.props.nodes.nodeQuery.entities
    ) {
      this.setState({
        nodes: Object.assign({}, this.props.nodes, {
          nodeQuery: {
            entities: this.sortNodes(),
          },
        }),
      });
    }
  }

  render() {
    if (this.state.nodes) {
      return (
        <ParagraphExtendedTeaserOverview
          content={this.props.content}
          usePager={this.props.content.entityBundle === "angebotsuebersicht"}
          nodes={this.state.nodes}
          skipQuery={true}
        />
      );
    } else {
      return false;
    }
  }
}

ParagraphExtendedTeaserOverviewKurs.propTypes = {
  content: PropTypes.shape({
    entityId: PropTypes.string,
    fieldDarstellung: PropTypes.oneOf(["small_big", "small_highlighted"]),
    fieldElementeProSeite: PropTypes.number,
    fieldPagerAufVollseiten: PropTypes.bool,
    fieldLinkZurUebersicht: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldTypExtended: PropTypes.oneOf([
      "news",
      "person",
      "projekt",
      "veranstaltung",
    ]),
    fieldPagerVerwenden: PropTypes.bool,
    fieldFilterwolke: PropTypes.bool,
    fieldFilterImText: PropTypes.bool,
    fieldFilterDialogBaum: PropTypes.bool,
    fieldSucheAktivieren: PropTypes.bool,
    fieldFilterImTextReference: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityBundle: PropTypes.oneOf([
            "filtertext_text",
            "filtertext_filter",
          ]),
          fieldFilterTextText: PropTypes.string,
          fieldFilter: PropTypes.shape({
            entity: PropTypes.shape({
              entityLabel: PropTypes.string,
              entityId: PropTypes.string,
            }),
          }),
        }),
      })
    ),
    fieldFilterDialogBaumReferen: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          entityId: PropTypes.string,
          entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
          fieldFilterTextText: PropTypes.string,
          fieldFilterMultiple: PropTypes.arrayOf(
            PropTypes.shape({
              targetId: PropTypes.string,
              entity: PropTypes.shape({
                entityLabel: PropTypes.string,
              }),
            })
          ),
        }),
      })
    ),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
  }),
  dispatch: PropTypes.func.isRequired,
  nodes: PropTypes.object.isRequired,
  skipQuery: PropTypes.bool,
};

export default graphql(teaserNodeQueryKursFilterTag, {
  name: "nodes",
  skip: (props) => props.content.fieldManuelleSortierung || props.skipQuery,
  options: (props) => ({
    variables: {
      limit: 1000,
      type: [
        props.content.fieldInhaltstyp
          ? props.content.fieldInhaltstyp
          : props.content.fieldTypExtended,
      ],
      tag: props.content.fieldSchlagwort
        ? props.content.fieldSchlagwort.map((item) => item.targetId.toString())
        : false,
      filterTagEnabled:
        props.content.fieldSchlagwort &&
        props.content.fieldSchlagwort.length > 0,
      date: moment().utc().format("YYYY-MM-DDTHH:mm:00"),
    },
  }),
})(connect()(ParagraphExtendedTeaserOverviewKurs));
