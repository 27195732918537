import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";

import Image from "../../image/image";
import { pagerFullPageContextAction } from "../../../app-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserPerson extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article className="node node-teaser teaser-person">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <Link
          onClick={() =>
            this.props.dispatch(
              pagerFullPageContextAction(this.props.pagerFullPage)
            )
          }
          className="flex-wrap"
          to={this.props.item.path.alias}
        >
          <Image
            data={this.props.item.fieldBildWCaption.entity.fieldMediaImage}
            nodeTitle={this.props.item.title}
          />
          <h3>{this.props.item.title}</h3>
          {this.props.item.fieldSchlagwort.length > 0 &&
            this.props.item.fieldSchlagwort[0].entity !== null && (
              <span className="top-line">
                {this.props.item.fieldSchlagwort[0].entity.name}
              </span>
            )}
        </Link>
      </article>
    );
  }
}

export const teaserPersonPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  fieldMail: PropTypes.string,
  fieldTelefon: PropTypes.string,
  fieldMobil: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  fieldBeschreibung: PropTypes.shape({
    processed: PropTypes.string,
  }),
  fieldKontakinformationen: PropTypes.arrayOf(PropTypes.shape({
    entity: PropTypes.shape({
      fieldContactType: PropTypes.string.isRequired,
      fieldWert: PropTypes.shape({
        processed: PropTypes.string.isRequired,
      })
    })
  })),
  fieldRolleOderFunktion: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldBildWCaption: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserPerson.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserPerson));
