import React, { Component } from "react";
import SimpleNavigation from "./simple-navigation";
import LogoButton from "../general-components/logo-button";

class SimpleHeader extends Component {
  render() {
    return (
      <div className="fixed-wrapper">
        <div className="container">
          <div className="row">
            <LogoButton />
            <SimpleNavigation footerConfig={this.props.footerConfig} footerMenu={this.props.footerMenu}/>
          </div>
        </div>
      </div>
    );
  }
}

export default SimpleHeader;
