import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A module representing a text paragraph.
 */
class ParagraphText extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-text": true,
      "background-color": this.props.content.fieldFarbigHinterlegen,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            {this.props.content.fieldTitel &&
              <div className="col-wrapper col-10 col-lg-5 title">
                <h3>
                  {this.props.content.fieldTitel}
                </h3>
              </div>
            }
            {this.props.content.fieldText &&
              <div className={`col-wrapper col-10 text ${this.props.content.fieldTitel ? 'col-lg-5' : ''}`}>
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.fieldText.processed,
                  }}
                />
              </div>
            }
          </div>
        </div>
      </section>
    );
  }
}

ParagraphText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldFarbigHinterlegen: PropTypes.bool,
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ParagraphText;
