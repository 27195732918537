import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import Slider from "react-slick";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";
import { connect } from "react-redux";

import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import { pagerFullPagesAction } from "../../../../../app-actions";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-projekt";
import SlideContent from "./slide-content";

class ComponentTeaserlistCarousel extends React.Component {

  slider = React.createRef();

  state = {
    slideIndex: 0
  };

  pushPagerFullPageConfig = () => {
    if (this.props.pagerFullPage && this.props.nodes.nodeQuery) {
      const pagerFullPagesConfig = {
        id: this.props.id,
        items:
          this.props.nodesConfig === "Manuell"
            ? this.props.manualNodes.map((item) => item.entity)
            : this.props.nodes.nodeQuery.entities,
        overviewLink: this.props.pagerFullPageOverviewLink,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.nodesConfig !== "Manuell" &&
      JSON.stringify(prevProps.nodes.nodeQuery) !==
      JSON.stringify(this.props.nodes.nodeQuery)
    ) {
      this.pushPagerFullPageConfig();
    }
  }

  toggleSliderForwards = (forwards) => {
    if (forwards) {
      this.slider.current.slickNext();
    } else {
      this.slider.current.slickPrev();
    }
  }

  render() {
    if (!this.props.manualNodes && this.props.nodes.loading) {
      return false;
    }

    const sliderSettings = {
      arrows: false,
      centerMode: false,
      dots: false,
      infinite: true,
      slidesToScroll: 1,
      focusOnSelect: true,
      touchMove: true,
      adaptiveHeight: false,
      variableWidth: true,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            adaptiveHeight: true,
            variableWidth: false
          }
        }
      ]
    };

    return (
      <>
        {this.props.content.fieldHeading && (
          <div className="container title">
            <div className="row">
              <div className="col-7 col-md-8">
                <h2>{this.props.content.fieldHeading}</h2>
              </div>
              <div className="col-3 col-md-2 arrow-wrapper">
                <button className="carousel-arrow carousel-prev inktrap" onClick={() => this.toggleSliderForwards(false)}>
                  ←
                </button>
                <button className="carousel-arrow carousel-next inktrap" onClick={() => this.toggleSliderForwards(true)}>
                  →
                </button>
              </div>
            </div>
          </div>
        )}
        {(this.props.nodesConfig === "Manuell" &&
          this.props.manualNodes.length >= 1) ||
        (this.props.nodesConfig !== "Manuell" &&
          this.props.nodes.nodeQuery &&
          this.props.nodes.nodeQuery.entities.length >= 3) ? (
          <>
            {this.props.nodesConfig === "Manuell" ? (
              <Slider {...sliderSettings} ref={this.slider}>
                {this.props.manualNodes.map(
                  (item, index) => (
                    <React.Fragment key={index}>
                      {(() => {
                        switch (item.entity.entityBundle) {
                          case "news":
                            return (
                              <TeaserNews
                                item={item.entity}
                                pagerFullPage={this.props.pagerFullPage}
                              />
                            );
                          case "person":
                            return (
                              <TeaserPerson
                                item={item.entity}
                                pagerFullPage={this.props.pagerFullPage}
                              />
                            );
                          default:
                            return null;
                        }
                      })()}
                    </React.Fragment>
                  )
                )}
              </Slider>
            ) : (
              <>
                <Slider {...sliderSettings} ref={this.slider}>
                  {this.props.nodes.nodeQuery && this.props.nodes.nodeQuery.entities.map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        {(() => {
                          switch (item.entityBundle) {
                            case "news":
                              return (
                                <TeaserNews
                                  item={item}
                                  pagerFullPage={this.props.pagerFullPage}
                                />
                              );
                            case "person":
                              return (
                                <TeaserPerson
                                  item={item}
                                  pagerFullPage={this.props.pagerFullPage}
                                />
                              );
                            default:
                              return null;
                          }
                        })()}
                      </React.Fragment>
                    )
                  )}
                  {this.props.manualNodes && this.props.nodesConfig === "Manuell" && this.props.manualNodes.map(
                    (item, index) => (
                      <React.Fragment key={index}>
                        {(() => {
                          switch (item.entityBundle) {
                            case "news":
                              return (
                                <TeaserNews
                                  item={item}
                                  pagerFullPage={this.props.pagerFullPage}
                                />
                              );
                            case "person":
                              return (
                                <TeaserPerson
                                  item={item}
                                  pagerFullPage={this.props.pagerFullPage}
                                />
                              );
                            default:
                              return null;
                          }
                        })()}
                      </React.Fragment>
                    )
                  )}
                </Slider>
              </>
            )}
            <div className="info-box">
              <div className="container">
                <div className="row">
                  <div className="col-10 col-md-8">
                    {this.props.nodesConfig === "Manuell"  ? (
                      <SlideContent item={this.props.manualNodes[this.state.slideIndex].entity}/>
                    ) : (
                      <SlideContent item={this.props.nodes.nodeQuery.entities[this.state.slideIndex]}/>
                    )}
                  </div>
                  <div className="counter col-10 col-md-2">
                    {this.state.slideIndex < 10 && 0}{this.state.slideIndex + 1} / {this.state.slideIndex < 10 && 0}{this.props.nodesConfig === "Manuell"  ? (this.props.manualNodes.length) : (this.props.nodes.nodeQuery.entities.length)}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  }
}

ComponentTeaserlistCarousel.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.oneOfType([
        teaserNewsPropTypes,
        teaserEventPropTypes,
        teaserPersonPropTypes,
        teaserGeneralPropTypes,
        teaserProjectPropTypes,
      ]),
    })
  ),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      targetId: PropTypes.string,
    })
  ),
  pagerFullPageOverviewLink: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  nodes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default graphql(teaserNodeQueryFilterTag, {
  name: "nodes",
  skip: (props) => props.nodesConfig === "Manuell",
  options: (props) => ({
    variables: {
      limit: props.count ? props.count : 100,
      type: props.type === "all" ? ["news", "person"] : [props.type],
      tag: props.tags.map((item) => item.targetId.toString()),
      filterTagEnabled: props.tags.length > 0,
    },
  }),
})(connect()(ComponentTeaserlistCarousel));
