import React, { Component } from "react";
import PropTypes from "prop-types";
import Masonry from "react-masonry-component";

import Image from "../../../image/image";
import { ArrowLeft32, ArrowRight32 } from "@carbon/icons-react";

/**
 * This get called from ParagraphBilderSlider and actually renders the images.
 */

class MasonryImages extends Component {
  state = {
    showMore: false,
  };

  toggleMasonry = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
  };

  render() {
    return (
      <div className="row flex-column-reverse flex-lg-row">
        <div className="col-10 col-lg-2 align-items-end align-items-lg-center button-wrapper">
          {this.props.bilder && this.props.bilder.length > 2 &&
            <button
              className="btn btn-primary"
              onClick={() => this.toggleMasonry()}
            >
              {this.state.showMore ? (
                <>weniger anzeigen</>
              ) : (
                <>mehr anzeigen</>
              )}
            </button>
          }
        </div>
        <div className="col-10 col-lg-8 masonry-wrapper">
          <div className="container">
            <Masonry
              className="row"
            >
              {this.props.bilder
                .slice(0, this.state.showMore ? 32 : 2)
                .map((item, index) => (
                  <article
                    key={index}
                    className="media-image col-10 col-md-5"
                  >
                    <Image
                      data={item.entity.fieldMediaImage}
                      credit={item.entity.fieldCredit}
                      caption={
                        item.defaultCaption === false
                          ? item.customCaption
                          : null
                      }
                      globalCaption={this.props.globalCaption}
                      wrapCaption={this.props.wrapCaption}
                    />
                  </article>
                ))}
            </Masonry>
          </div>
        </div>
      </div>
    );
  }
}

MasonryImages.propTypes = {
  bilder: PropTypes.arrayOf(
    PropTypes.shape({
      defaultCaption: PropTypes.bool,
      customCaption: PropTypes.string,
      entity: PropTypes.shape({
        fieldCredit: PropTypes.string,
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    })
  ),
  globalCaption: PropTypes.string,
  wrapCaption: PropTypes.bool,
};

export default MasonryImages;
