import { Link } from "react-router-dom";
import React from "react";
import moment from "moment-timezone";

moment.tz.setDefault("Europe/Berlin");

const TeaserKursTime = ({
  item,
  timeFormat,
  setOpen,
  open,
  hideBooking,
  hideAvailablePlaces,
  futureAppointments,
}) => {
  const isEvent =
    item.fieldKurstyp === "Einzelveranstaltung" &&
    item.fieldVeranstaltungsart === "Veranstaltung";

  const isCafe =
    item.fieldKurstyp === "Einzelveranstaltung" &&
    item.fieldVeranstaltungsart === "Cafe";

  let bookingButton = null,
    bookingButtonLabel = null;

  if (!!item.fieldHauptkurs?.entity.fieldBuchenButton?.url?.path) {
    bookingButton = item.fieldHauptkurs.entity.fieldBuchenButton.url.path;
    bookingButtonLabel = item.fieldHauptkurs.entity.fieldBuchenButton.title;
  }

  if (!!item.fieldBuchenButton?.url?.path) {
    bookingButton = item.fieldBuchenButton.url.path;
    bookingButtonLabel = item.fieldBuchenButton.title;
  }

  const getTime = () => {
    if (
      isCafe &&
      item.fieldTermine.length > 0 &&
      item.fieldTermine[0].entity != null
    ) {
      return (
        <span className="time-element">
          {moment
            .utc(item.fieldTermine[0].entity.fieldDatumKursTermin?.value)
            .local()
            .format(timeFormat)}
          —{" "}
          {moment
            .utc(item.fieldTermine[0].entity.fieldDatumKursTermin?.endValue)
            .local()
            .format(timeFormat)}{" "}
          Uhr
        </span>
      );
    }

    if (
      item.fieldTermine.length === 0 ||
      isEvent ||
      item.fieldKurstyp === "Einzelveranstaltung"
    ) {
      return `${moment
        .utc(item.fieldDatumKurs.value)
        .local()
        .format(timeFormat)} — ${moment
        .utc(item.fieldDatumKurs.endValue)
        .local()
        .format(timeFormat)} Uhr`;
    }

    return `${moment
      .utc(item.fieldTermine[0].entity?.fieldDatumKursTermin.value)
      .local()
      .format(timeFormat)} — ${moment
      .utc(item.fieldTermine[0].entity?.fieldDatumKursTermin.endValue)
      .local()
      .format(timeFormat)} Uhr`;
  };

  return (
    <>
      <div className="top">{item.fieldAngebotsdauer}</div>
      <div className="times">{getTime()}</div>
      <small className="subscribe">
        {!hideBooking ? (
          <>
            {item.fieldFreiePlaetze > 0 ? (
              <>
                {!hideAvailablePlaces &&
                  <>
                    noch {item.fieldFreiePlaetze}{" "}
                    {item.fieldFreiePlaetze == 1 ? "Platz" : "Plätze"} frei
                  </>
                }

                {bookingButton ? (
                  <>
                    {" "}
                    →{" "}
                    <a target="_blank" href={bookingButton}>
                      {bookingButtonLabel}
                    </a>
                  </>
                ) : null}
              </>
            ) : (
              <>
                Ausgebucht
              </>
            )}
          </>
        ) : null}
      </small>
    </>
  );
};

export default TeaserKursTime;
