import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {self} from "../config";
import LoadingIndicator from "../general-components/loading-indicator";
import FooterSitemap from "./components/footer-sitemap";
import SocialIcons from "./../general-components/social-icons";
import ParagraphNewsletteranmeldung
  from "../general-components/content-base/paragraphs/newsletter/paragraph-newsletteranmeldung";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo Add title attribute to links.
 */
class PageFooter extends Component {
  render() {
    if (this.props.footerConfig && this.props.menu) {
      return (
        <div className="container">
          {this.props.footerConfig && this.props.menu && (
            <>
              {this.props.footerConfig.fieldFooterSitemapZeigen && (
                <FooterSitemap
                  menu={
                    this.props.footerConfig.fieldMenue
                      ? this.props.footerConfig.fieldMenue.targetId
                      : "main"
                  }
                />
              )}
              {this.props.footerConfig.fieldNewsletterText && this.props.footerConfig.fieldNewsletterLink &&
                <div className="row newsletter-register-link">
                  <div className="col-10 d-flex flex-wrap align-items-center">
                    <div
                      className="newsletter-text"
                      dangerouslySetInnerHTML={{
                        __html: this.props.footerConfig.fieldNewsletterText.processed,
                      }}
                    />
                    <a className="newsletter-button btn btn-secondary" href={this.props.footerConfig.fieldNewsletterLink?.url.path}>
                      {this.props.footerConfig.fieldNewsletterLink?.title}
                      <span className="inktrap"> ↗</span>
                    </a>
                  </div>
                </div>
              }
              <div className="row">
                <div className="col-10">
                  <div className="footer-wrapper">
                    <div className="footer-menu-wrapper">
                      <nav className="footer-menu">
                        <ul>
                          {this.props.menu.links.map((item, index) => (
                            <li key={index}>
                              <Link to={item.url.path}>
                                {item.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </nav>
                      {this.props.footerConfig.fieldTelefon &&
                        <div className="telefon info">
                          <span className="label">Telefon</span>
                          <span className="value">{this.props.footerConfig.fieldTelefon}</span>
                        </div>
                      }
                      {this.props.footerConfig.fieldEMail &&
                        <div className="e-mail info">
                          <span className="label">E-Mail</span>
                          <a className="value" href={`mailto:${this.props.footerConfig.fieldEMail}`}>{this.props.footerConfig.fieldEMail}</a>
                        </div>
                      }
                      <div className="social-icons-wrapper">
                        <SocialIcons content={this.props.footerConfig} />
                      </div>
                      {this.props.footerConfig.fieldText &&
                        <div
                          className="text info"
                          dangerouslySetInnerHTML={{
                            __html: this.props.footerConfig.fieldText.processed,
                          }}
                        />
                      }
                    </div>
                    <div className="jks-logo-wrapper">
                      <img src={`${self}/jks-wortlogo.svg`} className="jks-logo" alt={"Logo der Jugendkunstschule Dresden"}/>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    }

    return <LoadingIndicator />;
  }
}

PageFooter.propTypes = {
  adminApp: PropTypes.bool,
  footerConfig: PropTypes.shape({
    fieldFooterSitemapZeigen: PropTypes.bool,
    fieldCopyright: PropTypes.string,
    fieldMenue: PropTypes.shape({
      targetId: PropTypes.string,
    }),
    fieldFacebook: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTwitter: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      url: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
  menu: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.shape({
          path: PropTypes.string,
        }),
      })
    ),
  }),
};

export default connect(mapStateToProps)(PageFooter);
