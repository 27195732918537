import React, { Component } from "react";
import { Link } from "react-router-dom";
import {self} from "../config";

class LogoButton extends Component {
  render() {
    return (
      <Link className="site-logo-button" to="/">
        <img src={`${self}/jks-logo.svg`} className="jks-logo" alt={"Logo der Jugendkunstschule Dresden"}/>
      </Link>
    );
  }
}

LogoButton.propTypes = {};

export default LogoButton;
