import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link32 } from "@carbon/icons-react"

class ParagraphSectionheading extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-sectionheading": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-15 col-lg-8">
              <h2
                id={this.props.content.fieldHeading
                  .toLowerCase()
                  .replace(/\W/g, "-")}
                className="section-heading"
              >
                {this.props.content.fieldHeading}
              </h2>
            </div>
            {this.props.content.fieldSetAnchorLink && (
              <div className="col-1">
                <a
                  className="anchor"
                  href={`#${this.props.content.fieldHeading
                    .toLowerCase()
                    .replace(/\W/g, "-")}`}
                >
                  <Link32 />
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

ParagraphSectionheading.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool
  })
};

export default ParagraphSectionheading;
