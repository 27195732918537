import React, { Component } from "react";
import Loader from "react-loader-spinner";
import {self} from "../config";

class LoadingIndicator extends Component {
  render() {
    return (
      <div className={`loading-indicator-wrap ${this.props.fullPage ? 'fullpage' : ''}`}>
        {this.props.fullPage ? (
          <img src={`${self}/jks-wortlogo.svg`} className="jks-logo" alt={"Logo der Jugendkunstschule Dresden"}/>
        ) : (
          <Loader
            type="TailSpin"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={50000}
          />
        )}
      </div>
    );
  }
}

export default LoadingIndicator;
