import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Image from "../../image/image";
import TeaserPersonOverlay from "./teaser-person-overlay";
import EditButton from "../../../backend/edit-button";
import { teaserPersonPropTypes } from "./teaser-person";
import { encodeUrl } from "../../../lib/encode-url";

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

const TeaserPersonOverview = (props) => {
  let imgData = props.item.fieldBildWCaption;

  return (
    <>

    </>
  );
};

TeaserPersonOverview.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  item: teaserPersonPropTypes,
};

export default connect(mapStateToProps)(withRouter(TeaserPersonOverview));
