import React, { useEffect, useRef, useState } from "react";
import Image from "../../image/image";
import { encodeUrl } from "../../../lib/encode-url";
import Link from "../../link/link";

import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';

const TeaserHighlight = ({ item }) => {
  /**
   * If fields are filled, use the fields content.
   * Otherwise fill with content from the targeted content types.
   */
  const content = {};
  const refContent = {
    entityBundle: "landingpage",
    ...item.fieldReferencedContent?.entity
  };

  // set title globally
  content.title = item.fieldTitle || refContent.entityLabel;


  switch (refContent.entityBundle) {
    case "kurs": {
      content.text =
        item.fieldText?.processed || refContent.fieldText?.processed;
      content.image =
        item.fieldImage?.entity?.fieldMediaImage ||
        refContent.fieldBild?.entity.fieldMediaImage;

      content.link = {
        url: {
          path: `/programm#${encodeUrl(
            [refContent.title, refContent.entityId].join("-")
          )}`,
        },
      };
      break;
    }

    case "landingpage": {
      content.text =
        item.fieldText?.processed || refContent.fieldText?.processed;
      content.image =
        item.fieldImage?.entity.fieldMediaImage || refContent.fieldTeaserBild;
      content.link = {
        url: {
          path: refContent.path?.alias,
        },
      };
      break;
    }
  }


  return (
    <div className="inner-wrapper">
      {content.title && content.image && (
        <Link link={content.link}>
          <div className="teaser-imager-wrapper">
            {content.image && <Image data={content.image} />}
          </div>
        </Link>
      )}
      <div className="info-wrapper">
        {content.title && content.link && (
          <Link link={content.link}>
            <Hyphenated language={de}>
              {content.title && <h3>{content.title}</h3>}
            </Hyphenated>
          </Link>
        )}
        {content.text && (
          <div
            className="teaser-text"
            dangerouslySetInnerHTML={{ __html: content.text }}
          />
        )}
      </div>
    </div>
  );
};

export default TeaserHighlight;
