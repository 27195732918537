import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ArrowDownRight32 } from "@carbon/icons-react";

import Link, { LinkPropType } from "../../../link/link";
import ErrorBoundary from "../../../../error-boundary";

class ParagraphClickSuggestions extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-click-suggestions": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-10">
              <div className="row">
                <div className="col-10">
                  <ul>
                    {this.props.content.fieldLinks.map((item, index) => (
                      <React.Fragment key={index}>
                        <li>
                          <ErrorBoundary>
                            <Link link={item} />
                            <span className="inktrap">↗</span>
                          </ErrorBoundary>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphClickSuggestions.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldLinks: PropTypes.arrayOf(LinkPropType),
  }),
};

export default ParagraphClickSuggestions;
