import React, {useEffect, useState} from "react";
import ErrorBoundary from "../../../../../error-boundary";
import TeaserKurs from "../../../../teaser-base/kurs/teaser-kurs";
import LoadingIndicator from "../../../../loading-indicator";
import { ArrowDown16 } from "@carbon/icons-react";

const KursOverview = (props) => {
  // apply pagination only on "angebotsübersicht"
  // therefore, set shown items to 10, otherwise show 100
  const defaultPagination = props.content.entityBundle === "kurse_uebersicht" ? 20 : 5;
  const [showAmount, setShowAmount] = useState(props.usePager ? defaultPagination : 1000);
  const [teaserFound, setTeaserFound] = useState(false);
  const [teaserCanBeFound, setTeaserCanBeFound] = useState(false);
  const [nodeIdFromUrl, setNodeIdFromUrl] = useState("");

  const searchForTeaserToOpen = (hash) => {
    // Hash value consists of title and Node id, url encoded and separated by "-", therefore last item after "-" will be the node id
    let content = props.itemsToShow.slice(0, showAmount);

    // Loop through shown Elements and filter them for matching node, if matching elements are less than one, repeat and load till all Elements are loaded
    if ((content.filter((loadedItem) => loadedItem.entityId === nodeIdFromUrl).length < 1) && (content.length !== props.itemsToShow.length)) {
      setShowAmount((amount) => amount + defaultPagination);
    } else {
      setTeaserFound(true);
    }
  }

  // Reinvoke search for teaser if ammount has changed
  useEffect(() => {
    if (window.location.hash && teaserCanBeFound && !teaserFound) {
      searchForTeaserToOpen(window.location.hash);
    }
  }, [showAmount]);

  // Open Teaser if it can be found
  useEffect(() => {
    if (window.location.hash && props.usePager && !teaserFound && teaserCanBeFound) {
      searchForTeaserToOpen(window.location.hash);
    }
  }, [teaserCanBeFound]);

  useEffect(() => {
    /* Search if a teaser is needed to be opened, that is not part of the by default shown results of the first page in the pager!
    * Important: Firstly checks if the given id exists on this page. If not, nothing will happen.
     */
    const nodeIdFromUrlHelper = window.location.hash.split("-").pop();
    setNodeIdFromUrl(nodeIdFromUrlHelper);
    setTeaserCanBeFound(props.itemsToShow.filter((loadedItem) => loadedItem.entityId === nodeIdFromUrlHelper).length === 1);

  }, []);

  return (
    !!props.itemsToShow?.length ? (
      <>
        {props.itemsToShow.slice(0, showAmount).map((item, index) => (
          <ErrorBoundary key={index}>
            <TeaserKurs
              item={item}
              pagerFullPage={
                props.content.fieldPagerAufVollseiten
                  ? props.content.entityId
                  : false
              }
            />
          </ErrorBoundary>
        ))}
        {showAmount <= props.itemsToShow?.length && props.usePager && (
          <div className="d-flex justify-content-center show-more-wrapper">
            <button
              className="btn btn-primary"
              onClick={() => setShowAmount((amount) => amount + defaultPagination)}
            >
              mehr anzeigen <ArrowDown16 />
            </button>
          </div>
        )}
      </>
    ) : (
      <LoadingIndicator fullPage={true}/>
    )
  );
};

export default KursOverview;
