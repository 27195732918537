import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronDown24, ChevronUp24 } from "@carbon/icons-react";

class ParagraphStrukturierteListe extends Component {
  state = { open: false };

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-strukturierte-liste": true,
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldHeading &&
          <div className="title" style={{ backgroundColor: this.props.content.fieldFarben ? this.props.content.fieldFarben[0].color : ''}}>
            <div className="container">
              <div className="row">
                <div className="col-10">
                  <h3>{this.props.content.fieldHeading}</h3>
                </div>
              </div>
            </div>
          </div>
        }
        <div className="container">
          <div className="row">
            {this.props.content.fieldAusklappenEinklappen && (
              <div className="col-10 col-md-3 d-md-flex justify-content-end align-items-baseline">
                <div
                  className="btn btn-secondary btn-icon"
                  onClick={() => this.setState({ open: !this.state.open })}
                >
                  {this.state.open ? (
                    <>
                      Einklappen <ChevronUp24 />
                    </>
                  ) : (
                    <>
                      Ausklappen <ChevronDown24 />
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="col-10">
              <div
                className={classNames({
                  "row content": true
                })}
              >
                {this.props.content.fieldListen.map((item, index) => (
                  <div
                    key={index}
                    className={classNames({
                      "list-item col-10": true
                    })}
                    style={{ backgroundColor: this.props.content.fieldFarben ? this.props.content.fieldFarben[index & 1 ? 0 : 1].color : ''}}
                  >
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{ __html: item.processed }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {this.props.content.fieldHint && (
          <div
            className={classNames({
              "hint text": true,
              "d-none": !this.state.open,
              "d-flex":
                this.state.open ||
                !this.props.content.fieldAusklappenEinklappen,
            })}
          >
            <div className="container">
              <div className="row">
                <div className="col-10">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.content.fieldHint.processed,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

ParagraphStrukturierteListe.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldAusklappenEinklappen: PropTypes.bool,
    fieldHint: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldListen: PropTypes.arrayOf(
      PropTypes.shape({
        processed: PropTypes.string,
      })
    ),
  }),
};

export default ParagraphStrukturierteListe;
