import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { Link } from "react-router-dom";

import menuQuery from "../general-components/menu-query.graphql";
import SocialIcons from "../general-components/social-icons";

/**
 * Close on menu link click.
 */
class SimpleNavigation extends Component {
  state = {
    menuOpen: false,
    openTree: false,
  };

  toggleFullScreenMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen }, () => {
      if (this.state.menuOpen) {
        document.body.classList.add("fullscreen-menu-open");
      } else {
        document.body.classList.remove("fullscreen-menu-open");
      }
    });
  };

  handleKeyDown = (event) => {
    console.log(event.key);

    if (event.key === "Enter" || event.key === "Escape") {
      this.toggleFullScreenMenu();
    }
  };

  render() {
    return (
      <>
        <nav className="col main-menu-desktop">
          <ul>
            {!this.props.headernavigationQuery.loading &&
              this.props.headernavigationQuery.menuByName &&
              this.props.headernavigationQuery.menuByName.links.map(
                (item, index) => (
                  <li key={index}>
                    <Link to={item.url.path}>{item.label}</Link>
                  </li>
                )
              )}
          </ul>
        </nav>
        <div
          id="toggle-fullscreen-menu"
          tabIndex={0}
          className={this.state.menuOpen ? "active" : ""}
          aria-label="toggle-full-screen-menu"
          onClick={() => this.toggleFullScreenMenu()}
          onKeyDown={this.handleKeyDown}
        >
          <div className="bar1" />
          <div className="bar2" />
          <div className="bar3" />
          <div className="bar4" />
        </div>

        <div
          id="fullscreen-menu"
          className={this.state.menuOpen ? "active" : ""}
        >
          <div className="nav-wrap">
            {!this.props.headernavigationQuery.loading && (
              <div className="header-navigation">
                <div className="container">
                  <div className="row">
                    <div className="col-10">
                      <nav className="header-navigation">
                        <ul>
                          {this.props.headernavigationQuery.menuByName &&
                            this.props.headernavigationQuery.menuByName.links.map(
                              (item, index) => (
                                <li
                                  onClick={() => this.toggleFullScreenMenu()}
                                  key={index}
                                >
                                  <Link to={item.url.path}>{item.label}</Link>
                                </li>
                              )
                            )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <nav className="main-menu-desktop container">
              <div className="row">
                <ul className="col-10">
                  {!this.props.mainMenuQuery.loading &&
                    this.props.mainMenuQuery.menuByName &&
                    this.props.mainMenuQuery.menuByName.links.map(
                      (item, index) => (
                        <li
                          onClick={() =>
                            item.url.path !== ""
                              ? this.toggleFullScreenMenu()
                              : ""
                          }
                          className=""
                          key={index}
                        >
                          <div className="">
                            <>
                              {item.url.path !== "" ? (
                                <Link to={item.url.path}>{item.label}</Link>
                              ) : (
                                <span
                                  className="topic-headline"
                                  onClick={() =>
                                    this.setState({ openTree: index })
                                  }
                                >
                                  {item.label}
                                </span>
                              )}

                              {item.links.length > 0 && (
                                <ul>
                                  {item.links.map((linkItem, linkIndex) => (
                                    <li
                                      onClick={() =>
                                        linkItem.url.path !== ""
                                          ? this.toggleFullScreenMenu()
                                          : ""
                                      }
                                      key={linkIndex}
                                    >
                                      <Link to={linkItem.url.path}>
                                        {linkItem.label}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </>
                          </div>
                        </li>
                      )
                    )}
                </ul>
              </div>
            </nav>
          </div>
          {this.props.footerConfig && (
            <div className="menu-footer">
              <div className="container">
                <div className="row">
                  <div className="col-10">
                    <nav className="footer-navigation">
                      <ul>
                        {this.props.footerMenu &&
                          this.props.footerMenu.links &&
                          this.props.footerMenu.links.map((item, index) => (
                            <li
                              onClick={() => this.toggleFullScreenMenu()}
                              key={index}
                            >
                              <Link to={item.url.path}>{item.label}</Link>
                            </li>
                          ))}
                      </ul>
                      <div className="social-icons-wrapper">
                        <SocialIcons content={this.props.footerConfig} />
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

SimpleNavigation.propTypes = {
  mainMenuQuery: PropTypes.object,
  oftenClickedMenuQuery: PropTypes.object,
};

export default compose(
  graphql(menuQuery, {
    options: {
      variables: { name: "main" },
    },
    name: "mainMenuQuery",
  }),
  graphql(menuQuery, {
    options: {
      variables: { name: "headernavigation" },
    },
    name: "headernavigationQuery",
  })
)(SimpleNavigation);
