import { encodeUrl } from "./encode-url";

const updateAccordion = (title, ref, preExpandedItem) => {
  let scrollPosition = ref.getBoundingClientRect().top + window.scrollY - 160;

  /* Removes Hash when clicking on opened Element, but not if its initally loaded by url
  * preExpandedItem should normally be an array filled with the entity id. But somehow, at the time
  * this Function gets triggered on "preExpanding" it is false.
  * It is never false if clicked, only if it's open programmatically.
  * May not be the safest way, but it is ok for now.
  */
  if (`#${encodeUrl(title)}` === window.location.hash && !!preExpandedItem) {
    // Remove Hash if user closes an opened element
    history.replaceState(null, null, ' ');
  } else {
    history.pushState(null, window.location.pathname, `#${encodeUrl(title)}`);
  }

  window.scrollTo({
    top: scrollPosition,
    behavior: "smooth",
  });
};

export { updateAccordion };
