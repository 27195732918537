import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import landingPageQuery from "./landingpage.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import {specialDesignAction} from "../app-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  specialDesign: reduxStore.appStore.specialDesign
});

class Landingpage extends Component {

  componentDidMount() {
    const specialDesign = this.props.data.route.entity.fieldSpecialDesign?.entity?.name.toLowerCase();

    console.log(!!specialDesign || this.props.specialDesign !== specialDesign);

    if (!!specialDesign || this.props.specialDesign !== specialDesign) {
      console.log("push to store")
      this.props.dispatch(specialDesignAction(specialDesign));

    }
  }

  render() {
    if (this.props.data.route) {
      const published = this.props.data.route.entity.status
        ? "node-published"
        : "node-unpublished";

      return (
        <article
          className={`node-${this.props.data.route.entity.entityBundle} node-${this.props.data.route.entity.entityId} node-full-page ${published}`}
          data-special-design={this.props.data.route.entity.fieldSpecialDesign?.entity?.name.toLowerCase()}
        >
          <Helmet>
            <title>{`Jugendkunstschule Dresden | ${this.props.data.route.entity.entityLabel}`}</title>
            {this.props.data.route.entity.fieldMetaDescription &&
              <meta property="og:description" content={this.props.data.route.entity.fieldMetaDescription}/>
            }
          </Helmet>

          {this.props.data.route.entity.fieldModules && (
            <ContentBase content={this.props.data.route.entity.fieldModules} location={this.props.location}/>
          )}

          {this.props.pagerFullPageContextId &&
            this.props.pagerConfig.filter(
              (config) => config.id === this.props.pagerFullPageContextId
            ).length > 0 && (
              <PagerFullPage
                config={
                  this.props.pagerConfig.filter(
                    (config) => config.id === this.props.pagerFullPageContextId
                  )[0]
                }
                currentId={this.props.data.route.entity.entityId}
              />
            )}
        </article>
      );
    }

    return <LoadingIndicator fullPage={true}/>;
  }
}

Landingpage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default graphql(landingPageQuery, {
  options: (props) => ({
    variables: { path: props.location.pathname },
  }),
})(connect(mapStateToProps)(withRouter(Landingpage)));
