import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import Image from "../../image/image";
import ErrorBoundary from "../../../error-boundary";
import { updateAccordion } from "../../../lib/update-accordion";
import { encodeUrl } from "../../../lib/encode-url";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserAngebot extends Component {
  static defaultProps = { pagerFullPage: false };
  teaser = React.createRef();

  constructor(props) {
    super(props);

    let preExpandedItem = null;

    if (this.props.item && encodeUrl(this.props.item.title) === this.props.location.hash.replace('#', '')) {
      preExpandedItem = this.props.item.entityId;
    }

    this.state = {
      preExpandedItem,
      initial: true
    };
  }

  componentDidMount() {
    if (this.state.initial && this.props.item && encodeUrl(this.props.item.title) === this.props.location.hash.replace('#', '')) {
      setTimeout(() => {
        updateAccordion(this.props, this.teaser.current);
        this.setState({
          initial: false
        })
      }, 500);
    }
  }

  render() {
    return (
      <article
        className="node node-teaser teaser-angebot"
        ref={this.teaser}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.entityId}
          destinationRoute={this.props.location.pathname}
        />
        <React.Suspense fallback={<div/>}>
          <Accordion
            allowZeroExpanded={true}
            onChange={() => updateAccordion(this.props, this.teaser.current)}
            preExpanded={[this.state.preExpandedItem]}
          >
            <AccordionItem
              uuid={this.props.item.entityId}
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className="container">
                    <div className="row">
                      <div className="col-10 col-md-1">
                        <h3>{this.props.item.fieldNummer}</h3>
                      </div>
                      <div className="col-10 col-md-5 grow">
                        <h3>{this.props.item.title}</h3>
                        {this.props.item.fieldSchlagwort.length > 0 && this.props.item.fieldSchlagwort[0].entity &&
                          <div className="tag-wrapper">
                            {this.props.item.fieldSchlagwort.map((item, index) => (
                              <span key={index} className="tag">
                              {item.entity.name} <span className="inktrap">↗</span>
                            </span>
                            ))}
                          </div>
                        }
                      </div>
                      <div className="col-10 col-md-3">
                        <h3>{this.props.item.fieldAltersempfehlung}</h3>
                      </div>
                      <div className="col-10 col-md-1 collapse-icon">
                        <button className="inktrap">↓</button>
                      </div>
                    </div>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="container max">
                  <div className="row">
                    <div className="col-10 col-md-7 text">
                      <div className="text-wrap main-text">
                        {this.props.item.fieldText &&
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: this.props.item.fieldText.processed,
                            }}
                          />
                        }
                      </div>
                      <div className="text-wrap additional-text">
                        {this.props.item.fieldZusatztext &&
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: this.props.item.fieldZusatztext.processed,
                            }}
                          />
                        }
                      </div>
                    </div>
                    <div className="col-10 col-md-3 image">
                      <div className="text-wrap">
                        {this.props.item.fieldTeaserbild &&
                          <div className="image-wrapper">
                            <Image
                              data={this.props.item.fieldTeaserbild.entity.fieldMediaImage}
                              nodeTitle={this.props.item.title}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </React.Suspense>

      </article>
    );
  }
}

export const teaserAngebotPropTypes = PropTypes.shape({
  entityId: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTeaserbild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
});

TeaserAngebot.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserAngebotPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserAngebot));
