import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ErrorBoundary from "../../../../../error-boundary";
import TeaserEventList from "./component-teaser-event-list";
import Link from "../../../../link/link";
import {teaserEventPropTypes} from '../../../../teaser-base/event/teaser-event';

class ParagraphTeaserListEvents extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-list": true,
      [`paragraph-teaser-list-${this.props.content.fieldAnzahlDerAnzuzeigendenI}`]: true,
      [`paragraph-teaser-list-${this.props.content.fieldTyp}`]: true,
      "paragraph paragraph-teaser-list-slick": this.props.content.fieldKarussel,
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldHeading && (
          <div className="container">
            <div className="row">
              <div className="col-10">
                <h2>{this.props.content.fieldHeading}</h2>
              </div>
            </div>
          </div>
        )}

        <div className="container">
          <div className="row">
            <ErrorBoundary>
              <TeaserEventList
                count={
                  this.props.content.fieldAnzahlDerAnzuzeigendenI
                    ? this.props.content.fieldAnzahlDerAnzuzeigendenI
                    : 100
                }
                manualNodes={this.props.content.fieldBeitraegeVeranstaltung}
                nodesConfig={this.props.content.fieldAutmatischOderManuell}
                type={this.props.content.fieldTyp}
                tags={this.props.content.fieldSchlagwort}
              />
            </ErrorBoundary>
          </div>
        </div>

        {this.props.content.fieldMehrMeldungenButtonZeig &&
          this.props.content.fieldMehrMeldungenButton.title && (
            <div className="container">
              <div className="row">
                <div className="col-10 d-md-flex justify-content-md-end">
                  <ErrorBoundary>
                    <Link
                      link={this.props.content.fieldMehrMeldungenButton}
                      className="btn btn-primary more"
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          )}
      </section>
    );
  }
}

ParagraphTeaserListEvents.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldKarussel: PropTypes.bool,
    fieldMehrMeldungenButtonZeig: PropTypes.bool,
    fieldTyp: PropTypes.oneOf(["veranstaltung"]),
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.string,
      })
    ),
    fieldBeitraegeVeranstaltung: PropTypes.arrayOf(
      PropTypes.shape({
        entity: teaserEventPropTypes,
      })
    ),
    fieldAutmatischOderManuell: PropTypes.oneOf([
      "Automatisch (chronologisch)",
      "Manuell",
    ]),
    fieldMehrMeldungenButton: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
  })
};

export default ParagraphTeaserListEvents;
