import React, { Component } from "react";
import PropTypes from "prop-types";
import { teaserNewsPropTypes } from "../../../../teaser-base/news/teaser-news";
import { teaserPersonPropTypes } from "../../../../teaser-base/person/teaser-person";
import OutsideClickHandler from 'react-outside-click-handler';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { AccordingItem } from "carbon-components-react";

import classNames from "classnames";
import moment from "moment";
import ErrorBoundary from "../../../../../error-boundary";
import ComponentExtendedTeaserOverviewSearch from "./component-extended-teaser-overview-search";

class ComponentFilterCloud extends Component {
  static defaultProps = { wrap: true, preventEmptyResult: false };
  filterCloud = React.createRef();

  getTagsFromItems = (items, filterField) => {
    let tags = [];

    for (const item of items) {
      if (item[filterField] == null) {
        continue;
      }
      // Text field.
      if (typeof item[filterField] === "string") {
        if (!tags.some(({ entityId }) => entityId === item[filterField])) {
          tags = [
            ...tags,
            {
              entityId: item[filterField],
              name: item[filterField],
            },
          ];
        }

        continue;
      }

      // Single Entity reference field.
      if (
        typeof item[filterField] === "object" &&
        !Array.isArray(item[filterField]) &&
        item[filterField].entity.name !== null &&
        item[filterField].entity.entityId !== null
      ) {
        if (
          !tags.some(
            ({ entityId }) => entityId === item[filterField].entity.entityId
          )
        ) {
          tags = [...tags, item[filterField].entity];
        }

        continue;
      }

      // Multi Entity reference field.
      for (const tag of item[filterField]) {
        if (
          tag.entity !== null &&
          tag.entity.name !== null &&
          !tags.some(({ entityId }) => entityId === tag.entity.entityId)
        ) {
          tags = [...tags, tag.entity];
        }
      }
    }

    return tags;
  };

  getFilters = (items, filtersInput) => {
    const filters = {};

    for (const filter of filtersInput) {
      filters[filter] = this.getTagsFromItems(items, filter);
    }

    return filters;
  };

  getMonthsFromContent = () => {
    let months = [];
    for (const item of this.props.items) {
      if (!months.includes(moment(item.fieldDatumKurs.endValue).format("YYYY-MM"))) {
        months = [...months, moment(item.fieldDatumKurs.endValue).format("YYYY-MM")];
      }
      if (!months.includes(moment(item.fieldDatumKurs.value).format("YYYY-MM"))) {
        months = [...months, moment(item.fieldDatumKurs.value).format("YYYY-MM")];
      }
    }
    return months.sort();
  }

  getDaysFromContent = () => {

  }

  toggleCollapse = (type) => {
    this.setState({
      collapseOpen: type === this.state.collapseOpen ? false : type
    })
  }

  resetFilter = (type) => {
    this.setState({
      collapseOpen: false
    });

    if (type === "month") {
      this.props.changeMonthFilter("reset");
    } else {
      this.props.changeDayFilter("reset");
    }
  }

  componentDidMount() {
    // Set offsetted top value to only show a part of the Filter on mobile
    if (typeof window !== "undefined" && window.innerWidth < 768) {
      let filterCloudHeight = this.filterCloud.current.clientHeight,
      tagFilterHeight = this.filterCloud.current.querySelector(".filter-type-schlagwort").clientHeight,
      searchHeight = this.filterCloud.current.querySelector(".search").clientHeight;

      this.filterCloud.current.style.setProperty('--sticky-top', `-${tagFilterHeight + searchHeight + 24}px`);
    }
  }

  state = {
    tags: this.props.items
      ? this.getFilters(this.props.items, this.props.filter)
      : [],
    collapseOpen: false
  };

  render() {
    const filters = Object.keys(this.state.tags);

    let filter = filters.map((filterName, index) => {
      const items = this.state.tags[filterName];

      switch (filterName) {
        case "timeFilters":
          return (
            <div className="filter-wrapper">
              <OutsideClickHandler
                onOutsideClick={() => this.toggleCollapse(false)}
              >
                <div className="filter-wrapper filter-type-time-filters" key={index}>
                  <div className={`collapse-wrapper month ${this.props.monthFilter.length >= 1 && !this.state.collapseOpen ? 'active' : 'inactive'}`}>
                    <button>
                    <span className="label" onClick={() => this.toggleCollapse("month")}>
                      Monat
                      {this.props.monthFilter.length >= 1 &&
                        <div className={"selected-options"}>
                          (
                          {this.props.monthFilter.map((month) => {
                            return(
                              <span className={"option-short"}>
                                {moment(month, "YYYY-MM").format("MMM")}
                              </span>
                            );
                          })}
                          )
                        </div>
                      }
                    </span>
                      {this.props.monthFilter.length >= 1 &&
                        <div className="reset-cross" onClick={() => this.resetFilter("month")}>
                          <span className="cross-icon" />
                        </div>
                      }
                    </button>
                    <div className={`collapsing ${this.state.collapseOpen === "month" ? 'show' : 'hide'}`}>
                      <ul>
                        {this.getMonthsFromContent().map((month, monthIndex) => {
                          return(
                            <li
                              role="button"
                              tabIndex={0}
                              key={monthIndex}
                              onClick={() => this.props.changeMonthFilter(month)}
                            >
                              <div className={`filter-option ${this.props.monthFilter && this.props.monthFilter.includes(month) ? 'active' : 'false'}`}>
                                {moment(month, "YYYY-MM").format("MMMM ’YY")}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className={`collapse-wrapper day ${this.props.dayFilter.length >= 1 && !this.state.collapseOpen ? 'active' : 'inactive'}`}>
                    <button>
                    <span className="label" onClick={() => this.toggleCollapse("day")}>
                      Wochentage
                      {this.props.dayFilter.length >= 1 &&
                        <div className={"selected-options"}>
                          (
                          {this.props.dayFilter.map((day) => {
                            return(
                              <span className={"option-short"}>
                                {(day === "Mo" || day === "Fr" || day === "Sa") &&
                                  <>
                                    {day}
                                  </>
                                }
                                {day === "Tu" &&
                                  <>
                                    Di
                                  </>
                                }
                                {day === "We" &&
                                  <>
                                    Mi
                                  </>
                                }
                                {day === "Th" &&
                                  <>
                                    Do
                                  </>
                                }
                                {day === "Su" &&
                                  <>
                                    So
                                  </>
                                }
                            </span>
                            );
                          })}
                          )
                        </div>
                      }
                    </span>
                      {this.props.dayFilter.length >= 1 &&
                        <div className="reset-cross" onClick={() => this.resetFilter("day")}>
                          <span className="cross-icon" />
                        </div>
                      }
                    </button>
                    <div className={`collapsing ${this.state.collapseOpen === "day" ? 'show' : 'hide'}`}>
                      <ul>
                        <li>
                          <div
                            className={`filter-option ${this.props.dayFilter && this.props.dayFilter.includes("Mo") ? 'active' : 'false'}`}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                              this.props.changeDayFilter("Mo")
                            }
                          >
                            Montag
                          </div>
                        </li>
                        <li>
                          <div
                            className={`filter-option ${this.props.dayFilter && this.props.dayFilter.includes("Tu") ? 'active' : 'false'}`}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                              this.props.changeDayFilter("Tu")
                            }
                          >
                            Dienstag
                          </div>
                        </li>
                        <li>
                          <div
                            className={`filter-option ${this.props.dayFilter && this.props.dayFilter.includes("We") ? 'active' : 'false'}`}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                              this.props.changeDayFilter("We")
                            }
                          >
                            Mittwoch
                          </div>
                        </li>
                        <li>
                          <div
                            className={`filter-option ${this.props.dayFilter && this.props.dayFilter.includes("Th") ? 'active' : 'false'}`}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                              this.props.changeDayFilter("Th")
                            }
                          >
                            Donnerstag
                          </div>
                        </li>
                        <li>
                          <div
                            className={`filter-option ${this.props.dayFilter && this.props.dayFilter.includes("Fr") ? 'active' : 'false'}`}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                              this.props.changeDayFilter("Fr")
                            }
                          >
                            Freitag
                          </div>
                        </li>
                        <li>
                          <div
                            className={`filter-option ${this.props.dayFilter && this.props.dayFilter.includes("Sa") ? 'active' : 'false'}`}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                              this.props.changeDayFilter("Sa")
                            }
                          >
                            Samstag
                          </div>
                        </li>
                        <li>
                          <div
                            className={`filter-option ${this.props.dayFilter && this.props.dayFilter.includes("Su") ? 'active' : 'false'}`}
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                              this.props.changeDayFilter("Su")
                            }
                          >
                            Sonntag
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`collapse-wrapper ${this.props.ferienFilter ? "active" : "false"}`}>
                    <button onClick={() => this.props.changeFerienFilter()}>
                    <span className="label">
                      Ferien
                    </span>
                    </button>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          );
        case "fieldZielgruppe":
          return (
            <div
              className={`filter-wrapper filter-type-${filterName
                .toLowerCase()
                .replace("field", "")}`}
              key={index}
            >
              {items.map((item, index) => {
                if (
                  this.props.preventEmptyResult &&
                  typeof this.props.filterItems === "function"
                ) {
                  const matchingItems = this.props.filterItems([
                    ...this.props.activeFilter,
                    {
                      ...item,
                      field: filterName,
                    },
                  ]);

                  if (matchingItems.length === 0) {
                    return null;
                  }
                }

                return (
                  <div
                    role="button"
                    tabIndex={0}
                    className={classNames({
                      btn: true,
                      "btn-secondary": true,
                      active:
                        this.props.activeFilter &&
                        this.props.activeFilter.filter(
                          (filterItem) =>
                            filterItem.entityId === item.entityId &&
                            filterItem.field === filterName
                        ).length > 0,
                    })}
                    key={index}
                    onClick={() =>
                      this.props.changeActiveFilter({
                        ...item,
                        field: filterName,
                      })
                    }
                  >
                    <span className="name">{item.name}</span>
                  </div>
                );
              })}
            </div>
          );
        default:
          return (
            <React.Fragment key={index}>
              <div
                className={`filter-wrapper filter-type-${filterName
                  .toLowerCase()
                  .replace("field", "")}`}
              >
                <Accordion allowZeroExpanded={true}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <img
                          src="/circled-plus.svg"
                          className="plus-icon"
                          alt="mehr erfahren icon"
                        />
                        {this.props.labels && this.props.labels[index] ? (
                          <span>{this.props.labels[index]}</span>
                        ) : (
                          <span>Filtern</span>
                        )}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="row">
                        <div className="col-10 filter-items-wrapper">
                          {items.map((item, index) => {
                            if (
                              this.props.preventEmptyResult &&
                              typeof this.props.filterItems === "function"
                            ) {
                              const matchingItems = this.props.filterItems([
                                ...this.props.activeFilter,
                                {
                                  ...item,
                                  field: filterName,
                                },
                              ]);

                              if (matchingItems.length === 0) {
                                return null;
                              }
                            }

                            return (
                              <div
                                role="button"
                                tabIndex={0}
                                className={classNames({
                                  filter: true,
                                  active:
                                    this.props.activeFilter &&
                                    this.props.activeFilter.filter(
                                      (filterItem) =>
                                        filterItem.entityId === item.entityId &&
                                        filterItem.field === filterName
                                    ).length > 0,
                                })}
                                key={index}
                                onClick={() =>
                                  this.props.changeActiveFilter({
                                    ...item,
                                    field: filterName,
                                  })
                                }
                              >
                                <span className="inktrap">↗</span>
                                <span className="cross-icon" />
                                <span className="name">{item.name}</span>
                              </div>
                            );
                          })}
                        </div>
                        {this.props.resetFilter &&
                          this.props.activeFilter
                            .map((item) => item.field)
                            .includes(filterName) && (
                            <div className={"reset-wrapper"}>
                              <div
                                role="button"
                                tabIndex={0}
                                className="filter filter-reset"
                                onClick={() =>
                                  this.props.resetFilter(filterName)
                                }
                              >
                                <span className="cross-icon"/>
                                <span className="name">Filter löschen</span>
                              </div>
                            </div>
                          )}
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </React.Fragment>
          );
      }
    });

    filter.push(
      <>
        {this.props.items && this.props.items?.length > 1 &&
          this.props.type === "kurse_uebersicht" && (
            <ErrorBoundary>
              <ComponentExtendedTeaserOverviewSearch
                changeActiveSearch={this.props.changeActiveSearch}
              />
            </ErrorBoundary>
          )}
      </>
    );

    return (
      <section className="filter-cloud" ref={this.filterCloud}>
        {this.props.wrap ? (
          <div className="container">
            <div className="row">
              {filter}
            </div>
          </div>
        ) : (
          <div>{filter}</div>
        )}
      </section>
    );
  }
}

ComponentFilterCloud.propTypes = {
  /**
   * The active filter.
   */
  activeFilter: PropTypes.array,
  /**
   * The function to change the active filter.
   */
  changeActiveFilter: PropTypes.func.isRequired,
  /**
   * The filter function used to filter the items. Used for the "prevent zero
   * results" feature.
   */
  filterItems: PropTypes.func,
  /**
   * The items to be filtered.
   */
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([teaserNewsPropTypes, teaserPersonPropTypes])
  ),
  /**
   * The name of the filtered field.
   */
  filter: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * The labels of the filters.
   */
  labels: PropTypes.arrayOf(PropTypes.string),
  /**
   * Whether to prevent zero results.
   */
  preventEmptyResult: PropTypes.bool,
  /**
   * Whether to wrap the filter cloud in a container.
   */
  wrap: PropTypes.bool.isRequired,
  /**
   * The function used to reset the filter(s)
   */
  resetFilter: PropTypes.func,
  /**
   * The function used to reset the filter(s)
   */
  resetFilterOn: PropTypes.arrayOf(PropTypes.string),
};

export default ComponentFilterCloud;
