import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Download24 } from "@carbon/icons-react";
import Image from "../../../image/image";

class ParagraphDownload extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-download": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <a
            className="row"
            href={
              this.props.content.fieldDatei.entity.fieldMediaFile.entity
                .url
            }
            download={true}
            target={"_blank"}
          >
            <div className="col download-icon d-flex justify-content-start">
              <button
                className="inktrap icon"
              >
                ↓
              </button>
            </div>
            <div
              className={"col title-wrapper"}
            >
              <h3>{this.props.content.fieldDatei.entity.entityLabel}</h3>
              {this.props.content.fieldDatei.entity.fieldBeschreibung && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html:
                    this.props.content.fieldDatei.entity.fieldBeschreibung
                      .processed,
                  }}
                />
              )}
            </div>
          </a>
        </div>
      </section>
    );
  }
}

ParagraphDownload.propTypes = {
  content: PropTypes.shape({
    fieldDatei: PropTypes.shape({
      entity: PropTypes.shape({
        fieldVorschaubild: PropTypes.shape({
          entity: PropTypes.shape({
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
            }),
          }),
        }),
        entityLabel: PropTypes.string.isRequired,
        fieldBeschreibung: PropTypes.shape({
          processed: PropTypes.string,
        }),
        fieldMediaFile: PropTypes.shape({
          entity: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
};

export default ParagraphDownload;
