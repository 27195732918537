import React, { Component } from "react";
import { Link } from "react-router-dom";
import {self} from "../config";
import {
  ArrowDownRight24,
  LogoFacebook24,
  LogoYoutube24,
  LogoFlickr24,
  LogoInstagram24,
  LogoLinkedin24,
  LogoPinterest24,
  LogoTwitter24,
  LogoXing24,
  LogoTumblr24,
  Email24
} from "@carbon/icons-react";

class SocialIcons extends Component {
  render() {
    return (
      <>
      {this.props.content.fieldEMail && (
          <a
            className="email"
            target="_blank"
            rel="noopener noreferrer"
            href={"mailto:" + this.props.content.fieldEMail}
          >
            <Email24 />
          </a>
        )}
        {this.props.content.fieldFacebook && (
          <a
            className="facebook"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.content.fieldFacebook.url.path}
          >
            <LogoFacebook24 />
          </a>
        )}
        {this.props.content.fieldYoutube && (
          <a
            className="youtube"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.content.fieldYoutube.url.path}
          >
            <LogoYoutube24 />
          </a>
        )}
        {this.props.content.fieldFlickr && (
          <a
            className="flickr"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.content.fieldFlickr.url.path}
          >
            <LogoFlickr24 />
          </a>
        )}
        {this.props.content.fieldInstagram && (
          <a
            className="instagram"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.content.fieldInstagram.url.path}
          >
            <LogoInstagram24 />
          </a>
        )}
        {this.props.content.fieldVimeo && (
          <a
            className="vimeo"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.content.fieldVimeo.url.path}
          >
            <svg
              aria-hidden="true"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink={"http://www.w3.org/1999/xlink"}
              x="0px"
              y="0px"
              viewBox="0 0 96 96"
              style={{ enableBackground: "new 0 0 96 96" }}
              xmlSpace="preserve"
            >
              <g id="XMLID_1_">
                <path
                  id="XMLID_5_"
                  className="st0"
                  d="M15.7,35.4C22.8,29,29.1,18,36.4,20.3c12.1,3.1,6.9,26.4,13.8,37.2c9.4-5.3,15.6-16.5,13-22.3
                          c-0.6-1.6,0.1-5.7-7.6-2.1c-2,0.9-1.9-2.6-1.6-3.1c7.2-7.9,11.3-11.2,16.2-11c4.1,0.7,11.4,5.3,10.1,16.5
                          C77.6,51.9,53.2,78,45.1,77c-9.3,2.1-14-27.4-21-41.2l-6.4,2.5C15,38.7,15.4,37,15.7,35.4L15.7,35.4z"
                  fill="#333333"
                />
              </g>
            </svg>
          </a>
        )}
        {this.props.content.fieldLinkedin && (
          <a
            className="linkedin"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.content.fieldLinkedin.url.path}
          >
            <LogoLinkedin24 />
          </a>
        )}
        {this.props.content.fieldPinterest && (
          <a
            className="pinterest"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.content.fieldPinterest.url.path}
          >
            <LogoPinterest24 />
          </a>
        )}
        {this.props.content.fieldTwitter && (
          <a
            className="twitter"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.content.fieldTwitter.url.path}
          >
            <LogoTwitter24 />
          </a>
        )}
        {this.props.content.fieldXing && (
          <a
            className="xing"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.content.fieldXing.url.path}
          >
            <LogoXing24 />
          </a>
        )}
        {this.props.content.fieldTumblr && (
          <a
            className="tumblr"
            target="_blank"
            rel="noopener noreferrer"
            href={this.props.content.fieldTumblr.url.path}
          >
            <LogoTumblr24 />
          </a>
        )}
      </>
    );
  }
}

SocialIcons.propTypes = {};

export default SocialIcons;
