import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "../../../link/link";

import Image from "../../../image/image";

class ParagraphTeaserImage extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-image": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div
            className={classNames({
              row: true,
              "flex-row-reverse":
                this.props.content.fieldImagePosition === "right",
            })}
          >
            <div
              className={classNames({
                "col-10 col-lg-5": true
              })}
            >
              <Image
                data={this.props.content.fieldImage.entity.fieldMediaImage}
                nodeTitle={this.props.content.fieldHeading}
              />
            </div>
            <div
              className={classNames({
                "col-10 col-lg-5": true
              })}
            >
              <h3>{this.props.content.fieldHeading}</h3>
              {this.props.content.fieldText && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.content.fieldText.processed,
                  }}
                />
              )}
              {this.props.content.fieldCallToAction && (
                <div className="read-more">
                  <Link
                    link={this.props.content.fieldCallToAction}
                  />
                  <div className="inktrap">
                    →
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphTeaserImage.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldImage: PropTypes.shape({
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
    fieldCallToAction: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.shape({
        path: PropTypes.string,
        routed: PropTypes.string,
      }),
    }),
    fieldText: PropTypes.shape({
      processed: PropTypes.string,
    }),
    fieldImagePosition: PropTypes.oneOf(["left", "right"]),
  }),
};

export default ParagraphTeaserImage;
